import { Box, BoxProps, Menu, MenuProps } from "@mui/material";

export const CloseButton = (props: BoxProps) => (
  <Box
    border="1px solid #D1DAF8"
    display="flex"
    borderRadius="0.3rem"
    style={{ cursor: "pointer" }}
    {...props}
  />
);

export const NotificationMenu = (props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    slotProps={{
      paper: {
        style: {
          minWidth: "20rem",
        },
        sx: {
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: "4px",
            border: "2px solid #f0f0f0",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        },
      },
    }}
    {...props}
  />
);
