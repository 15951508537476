import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useSidebarButton = (path?: string) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSelected = useMemo(() => {
    const pathBase = pathname.split("/")[1];

    return path === pathBase;
  }, [path, pathname]);

  const onClickButton = useCallback(() => {
    if (!path) return;

    navigate(path);
  }, [path]);

  return { onClickButton, isSelected };
};
