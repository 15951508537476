import { ReactSVG } from "react-svg";
import styled from "styled-components";

export const Container = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;

  width: 90%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.brand.secondary : "none"};
`;

export const Icon = styled(ReactSVG)<{ isSelected?: boolean }>`
  fill: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.sidebar.icon.active
      : theme.colors.sidebar.icon.default};
  stroke: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.sidebar.icon.active
      : theme.colors.sidebar.icon.default};
  margin-right: 0.5rem;

  g {
    path {
      fill: ${({ isSelected, theme }) =>
        isSelected
          ? theme.colors.sidebar.icon.active
          : theme.colors.sidebar.icon.default};
      stroke: none;
    }
  }
`;
