import { useMemo } from "react";
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "react-query";
import { useLocalStorage } from "@/context/LocalStorageContext";

export function useAuthenticatedQuery<T>(
  queryKey: (string | undefined)[],
  queryFn: QueryFunction<T, (string | undefined)[]>,
  options?: Omit<
    UseQueryOptions<T, unknown, T, (string | undefined)[]>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<T> {
  const { accessToken, isTokenExpired } = useLocalStorage();

  const enabled = useMemo(() => {
    const isAuthenticated = !!accessToken && !isTokenExpired;

    return options?.enabled !== undefined
      ? options.enabled && isAuthenticated
      : isAuthenticated;
  }, [options?.enabled, accessToken, isTokenExpired]);

  return useQuery(queryKey, (context) => queryFn(context), {
    ...options,
    enabled,
  });
}
