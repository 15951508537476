import { Box } from "@mui/material";
import { ColoredTooltip } from "./styles";
import Text from "@/components/Text";
import { ChallengeTooltipProps } from "./props.type";
import Close from "@mui/icons-material/Close";
import Theme from "@/utils/theme";

// For absolute positioning to work the component needs to be inside container with position = relative
export const ChallengeTooltip = ({
  containerProps,
  title,
  width = "18rem",
  isOpen,
  onClose,
  placement,
}: ChallengeTooltipProps) => {
  return (
    <Box {...containerProps}>
      <ColoredTooltip
        open={isOpen}
        arrow
        onClose={onClose}
        title={
          <Box display="flex" alignItems="center" width={{ width }}>
            <Text color={Theme.colors.neutral.darkest}>{title}</Text>
            <Box display="flex" style={{ cursor: "pointer" }} onClick={onClose}>
              <Close sx={{ color: Theme.colors.neutral.darkest }} />
            </Box>
          </Box>
        }
        PopperProps={{
          disablePortal: true,
          style: { zIndex: 10 },
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={placement}
      >
        <Box></Box>
      </ColoredTooltip>
    </Box>
  );
};
