import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const UploadIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.5V2.5M12 2.5L15 6M12 2.5L9 6"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 22.5H16C18.828 22.5 20.243 22.5 21.121 21.622C22 20.742 22 19.329 22 16.5V15.5C22 12.672 22 11.258 21.121 10.379C20.353 9.61095 19.175 9.51395 17 9.50195M7 9.50195C4.825 9.51395 3.647 9.61095 2.879 10.379C2 11.258 2 12.672 2 15.5V16.5C2 19.329 2 20.743 2.879 21.622C3.179 21.922 3.541 22.119 4 22.249"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default memo(UploadIcon);
