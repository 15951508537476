import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const OpenIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20001 4.8001C6.56349 4.8001 5.95304 5.05295 5.50295 5.50304C5.05286 5.95313 4.80001 6.56358 4.80001 7.2001V16.8001C4.80001 17.4366 5.05286 18.0471 5.50295 18.4972C5.95304 18.9472 6.56349 19.2001 7.20001 19.2001H16.8C17.4365 19.2001 18.047 18.9472 18.4971 18.4972C18.9471 18.0471 19.2 17.4366 19.2 16.8001V13.8001C19.2 13.641 19.2632 13.4884 19.3757 13.3758C19.4883 13.2633 19.6409 13.2001 19.8 13.2001C19.9591 13.2001 20.1117 13.2633 20.2243 13.3758C20.3368 13.4884 20.4 13.641 20.4 13.8001V16.8001C20.4 17.7549 20.0207 18.6706 19.3456 19.3457C18.6705 20.0208 17.7548 20.4001 16.8 20.4001H7.20001C6.24523 20.4001 5.32955 20.0208 4.65442 19.3457C3.97929 18.6706 3.60001 17.7549 3.60001 16.8001V7.2001C3.60001 6.24532 3.97929 5.32964 4.65442 4.65451C5.32955 3.97938 6.24523 3.6001 7.20001 3.6001H10.2C10.3591 3.6001 10.5117 3.66331 10.6243 3.77583C10.7368 3.88836 10.8 4.04097 10.8 4.2001C10.8 4.35923 10.7368 4.51184 10.6243 4.62436C10.5117 4.73688 10.3591 4.8001 10.2 4.8001H7.20001ZM13.2 4.2001C13.2 4.04097 13.2632 3.88836 13.3757 3.77583C13.4883 3.66331 13.6409 3.6001 13.8 3.6001H19.8C19.9591 3.6001 20.1117 3.66331 20.2243 3.77583C20.3368 3.88836 20.4 4.04097 20.4 4.2001V10.2001C20.4 10.3592 20.3368 10.5118 20.2243 10.6244C20.1117 10.7369 19.9591 10.8001 19.8 10.8001C19.6409 10.8001 19.4883 10.7369 19.3757 10.6244C19.2632 10.5118 19.2 10.3592 19.2 10.2001V5.6485L14.2248 10.6249C14.1121 10.7376 13.9593 10.8009 13.8 10.8009C13.6407 10.8009 13.4879 10.7376 13.3752 10.6249C13.2625 10.5122 13.1992 10.3594 13.1992 10.2001C13.1992 10.0408 13.2625 9.88796 13.3752 9.7753L18.3516 4.8001H13.8C13.6409 4.8001 13.4883 4.73688 13.3757 4.62436C13.2632 4.51184 13.2 4.35923 13.2 4.2001Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(OpenIcon);
