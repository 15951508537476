import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarButtonProps } from "../components/Sidebar/components/SidebarButton/props.type";
import HomeIcon from "/assets/sidebar/home.svg";
import ProfileIcon from "/assets/sidebar/profile.svg";
import ChallengeIcon from "/assets/sidebar/challenge.svg";
import CourseIcon from "/assets/sidebar/course.svg";
import HelpIcon from "/assets/sidebar/help.svg";
import RegulationIcon from "/assets/sidebar/regulation.svg";
import { helpContactNumber } from "@/utils/help-contact-number";
import { realityInfo } from "@/utils/realityInfo";

export const usePageWrapper = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hideWrapperPaths = [
    "/sign-up",
    "/sign-in",
    "/forgot-password",
    "/password-recovery",
  ];

  const onClickHelp = () => {
    window.open(`https://wa.me/${helpContactNumber}`);
  };

  const sidebarButtons = useMemo(() => {
    const items: SidebarButtonProps[] = [
      { icon: HomeIcon, label: "PÁGINA INICIAL", path: "dashboard" },
      { icon: ProfileIcon, label: "MEU PERFIL", path: "profile" },
      { icon: ChallengeIcon, label: "DESAFIOS", path: "challenges" },
      { icon: CourseIcon, label: "CURSOS", path: "courses" },
    ];

    if (!!realityInfo.regulationUrl) {
      items.push({
        icon: RegulationIcon,
        label: "REGULAMENTO",
        path: "regulation",
      });
    }

    items.push({
      icon: HelpIcon,
      label: "AJUDA",
      overwriteClick: true,
      onClick: onClickHelp,
    });

    return items;
  }, [realityInfo.regulationUrl]);

  const hideWrapper = useMemo(() => {
    return hideWrapperPaths.includes(pathname);
  }, [hideWrapperPaths, pathname]);

  const showWebsiteButton = useMemo(() => {
    return pathname === "/sign-in";
  }, [pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location.pathname]);

  return { hideWrapper, sidebarButtons, showWebsiteButton };
};
