import { realityId } from "@/utils/reality-id";
import api from "../api";
import { CompletedCourses, Course } from "./types/course.types";

export const getCourses = async (): Promise<Course[] | undefined> => {
  const res = await api.get(`/realities/${realityId}/courses`);
  return res.data.data;
};

export const getCompletedCourses = async (): Promise<
  CompletedCourses | undefined
> => {
  const res = await api.get(`/realities/${realityId}/completed-courses`);
  return res.data;
};
