import { getCompletedCourses } from "@/services/courses";
import { useMemo } from "react";
import { useQuery } from "react-query";

export function useGetCompletedCourses() {
  const { data, isLoading } = useQuery("completed-courses", () =>
    getCompletedCourses()
  );

  const completedCoursesCount = useMemo(() => {
    if (isLoading) return 0;

    return data?.completedCourses ?? 0;
  }, [isLoading, data?.completedCourses]);

  return { completedCoursesCount };
}
