import { useMemo, useState } from "react";

export const usePasswordInput = (flagProp?: boolean) => {
  const [showPasswordAux, setShowPasswordAux] = useState(false);

  const showPassword = useMemo(() => {
    if (flagProp !== undefined) {
      return flagProp;
    }

    return showPasswordAux;
  }, [flagProp, showPasswordAux]);

  const toggleAux = () => setShowPasswordAux(!showPasswordAux);

  return { showPassword, toggleAux };
};
