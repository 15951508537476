import { getStageChallenges } from "@/services/challenges";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { AccordionDetailsListProps } from "./props.type";

export const useAccordionDetailsList = ({
  isOpen,
  stageId,
  onChangeChallengesAmount,
}: AccordionDetailsListProps) => {
  const fetchChallenges = () => getStageChallenges(stageId);
  const {
    data: challenges,
    isLoading,
    refetch,
  } = useQuery(["stage-challenges", stageId], fetchChallenges, {
    enabled: false, //disable automatic refetching when the query mounts or changes query keys
  });

  useEffect(() => {
    onChangeChallengesAmount?.(challenges?.length);
  }, [challenges?.length]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return { challenges, isLoading };
};
