import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const DownArrowCircle = ({
  color = defaultIconProps.color,
  size = 84,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 83 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_442_5559)">
        <rect
          x="8.79098"
          y="75.209"
          width="66.418"
          height="65.418"
          rx="32.709"
          transform="rotate(-90 8.79098 75.209)"
          stroke={color}
          stroke-width="1.58197"
          shape-rendering="crispEdges"
        />
        <path
          d="M41.4589 27.721C41.7736 27.721 42.0754 27.846 42.2979 28.0686C42.5204 28.2911 42.6454 28.5928 42.6454 28.9075V52.1461L51.2978 43.4923C51.408 43.382 51.5389 43.2946 51.6829 43.2349C51.8269 43.1753 51.9813 43.1446 52.1372 43.1446C52.2931 43.1446 52.4475 43.1753 52.5915 43.2349C52.7355 43.2946 52.8664 43.382 52.9766 43.4923C53.0869 43.6025 53.1743 43.7334 53.234 43.8774C53.2936 44.0214 53.3243 44.1758 53.3243 44.3317C53.3243 44.4876 53.2936 44.642 53.234 44.786C53.1743 44.93 53.0869 45.0609 52.9766 45.1711L42.2984 55.8494C42.1882 55.9597 42.0573 56.0472 41.9133 56.1069C41.7692 56.1667 41.6148 56.1974 41.4589 56.1974C41.303 56.1974 41.1486 56.1667 41.0046 56.1069C40.8605 56.0472 40.7297 55.9597 40.6195 55.8494L29.9412 45.1711C29.7186 44.9485 29.5935 44.6465 29.5935 44.3317C29.5935 44.0169 29.7186 43.7149 29.9412 43.4923C30.1638 43.2696 30.4658 43.1446 30.7806 43.1446C31.0955 43.1446 31.3974 43.2696 31.6201 43.4923L40.2724 52.1461V28.9075C40.2724 28.5928 40.3974 28.2911 40.62 28.0686C40.8425 27.846 41.1442 27.721 41.4589 27.721Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_442_5559"
          x="0"
          y="0"
          width="83"
          height="84"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_442_5559"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_442_5559"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default memo(DownArrowCircle);
