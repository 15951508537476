import styled from "styled-components";
import { DividerProps } from "./props.type";

const Divider = styled.div<DividerProps>`
  background-color: ${({ color, theme }) =>
    color ?? theme.colors.neutral.light};
  margin: ${({ margin }) => margin};
`;

export const Horizontal = styled(Divider)`
  height: 1px;
  width: 100%;
`;

export const Vertical = styled(Divider)`
  height: auto;
  width: 1px;
`;
