import { createContext, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { getRealityInfo } from "@/services/reality";
import { isBefore } from "date-fns";

type RealityContext = {
  isSubscriptionOpen: boolean;
};

const RealityContext = createContext<RealityContext>({} as RealityContext);

type RealityProviderProps = {
  children: React.ReactNode;
};

export const RealityProvider = ({ children }: RealityProviderProps) => {
  const { data: realityInfo, isLoading } = useQuery(
    ["reality-info"],
    () => getRealityInfo(),
    { retry: true }
  );

  const isSubscriptionOpen = useMemo(() => {
    if (isLoading || !realityInfo?.finalSubscriptionDate) {
      return true;
    }

    return !isBefore(realityInfo.finalSubscriptionDate, new Date());
  }, [isLoading, realityInfo?.finalSubscriptionDate]);

  return (
    <RealityContext.Provider value={{ isSubscriptionOpen }}>
      {children}
    </RealityContext.Provider>
  );
};

export const useReality = (): RealityContext => useContext(RealityContext);
