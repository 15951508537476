import { memo } from "react";
import { CustomTextFieldProps } from "../TextField/props.type";
import TextField from "../TextField";
import ChevronDown from "@/ui/icons/ChevronDown";
import { IconWrapper } from "./styles";

const Select = (props: CustomTextFieldProps) => {
  return (
    <TextField
      select
      SelectProps={{
        displayEmpty: true,
        IconComponent: () => (
          <IconWrapper>
            <ChevronDown />
          </IconWrapper>
        ),
        MenuProps: {
          PaperProps: {
            sx: {
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
                border: "2px solid #f0f0f0",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            },
            style: {
              maxHeight: "200px",
              overflowY: "scroll",
            },
          },
        },
      }}
      {...props}
      value={props.value ?? ""}
    />
  );
};

export default memo(Select);
