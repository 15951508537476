import { memo } from "react";
import Text from "@/components/Text";
import SadFaceIcon from "/assets/icons/sad-face.svg";
import Theme from "@/utils/theme";
import { Box, Card } from "@mui/material";

const UserDidntAdvanceCard = () => {
  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
    >
      <Box display="flex" py="0.5rem" px="1rem">
        <Box display={"flex"} alignItems="center">
          <img
            src={SadFaceIcon}
            style={{ width: "1.4rem", height: "1.4rem" }}
          />

          <Text ml="1rem" color={Theme.colors.feedback.negative}>
            Infelizmente não foi dessa vez. Você não avançou para a próxima
            etapa. Mas não deixe de tentar na próxima edição.
          </Text>
        </Box>
      </Box>
    </Card>
  );
};

export default memo(UserDidntAdvanceCard);
