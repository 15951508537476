import { memo } from "react";
import { Box, CircularProgress } from "@mui/material";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { useResaleCodeModal } from "./useResaleCodeModal";
import MaskedInput from "@/components/MaskedInput";
import Dialog from "@/components/Dialog";
import CircleCheck from "@/ui/icons/CircleCheck";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ResaleCodeModal = ({ isOpen, onClose }: Props) => {
  const {
    resaleCode,
    setResaleCode,
    isLoading,
    error,
    onClickSubmit,
    closeModalAndClean,

    isOpenSuccessDialog,
    toggleSuccessDialog,
  } = useResaleCodeModal(onClose);

  return (
    <>
      <Modal
        isOpen={isOpen}
        containerProps={{
          width: { xs: "100%", sm: "500px" },
          padding: "1.5rem",
        }}
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Text
            fontSize="1rem"
            mb="1.5rem"
            alignSelf="center"
            textAlign="center"
            color={Theme.colors.neutral.dark}
          >
            CÓDIGO DA REVENDA
          </Text>

          <Text
            fontSize="1rem"
            mb="1.5rem"
            textAlign="center"
            color={Theme.colors.neutral.dark}
          >
            Agora você pode vincular o seu perfil a uma Revenda Quartzoli. Você
            tem um código da revenda? Se sim, insira-o abaixo.
          </Text>

          <MaskedInput
            label="Código de Revenda"
            value={resaleCode}
            setValue={(val) => setResaleCode(val.toUpperCase())}
            error={!!error}
            errorText={error}
            mask="a99999"
          />

          <Box display="flex" width="100%" justifyContent="end" mt="2rem">
            <Button
              fullWidth={false}
              style={{
                padding: "0.3rem",
                width: "8rem",
                marginRight: "1.5rem",
              }}
              onClick={closeModalAndClean}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              fullWidth={false}
              style={{ padding: "0.3rem", width: "8rem" }}
              onClick={onClickSubmit}
              disabled={!!error || !resaleCode || isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  size="1rem"
                  style={{ color: Theme.colors.feedback.info }}
                />
              ) : (
                "Salvar"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        isOpen={isOpenSuccessDialog}
        icon={<CircleCheck />}
        text="Código da revenda salvo com sucesso!"
        onConfirm={toggleSuccessDialog}
      />
    </>
  );
};

export default memo(ResaleCodeModal);
