import realitiesInfo from "@/utils/realities-info.json";
import { realityId } from "./reality-id";

const typedReality = realitiesInfo as unknown as RealitiesInfoJsonType;

export const realityInfo = typedReality[realityId] as RealityInfoType;

type RealityInfoType = {
  storageUrl: string;
  showResaleCode: boolean;
  confirmOccupationText: string;
  isDarkAccordion: boolean;
  gtmId: string;
  footerText: string;
  websiteUrl?: string;
  regulationUrl?: string;
};

type RealitiesInfoJsonType = { [key: number]: RealityInfoType };
