import { realityId } from "@/utils/reality-id";
import api from "../api";
import { UserNotification } from "./types/notification.types";

export const getUserNotifications = async (): Promise<
  UserNotification[] | undefined
> => {
  const res = await api.get(`/realities/${realityId}/notifications`);

  return res.data;
};
