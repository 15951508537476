import styled from "styled-components";

import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import Theme from "@/utils/theme";
import { FooterVariant } from "./props.type";

export const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ bgColor }) => bgColor};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div<{ variant?: FooterVariant }>`
  align-self: flex-end;
  background-color: ${Theme.colors.footer.background.default};
  padding: 16px 32px;
  border-radius: 16px 0 0 16px;

  ${({ variant }) =>
    variant === "auth" &&
    `
      align-self: stretch;
      display: flex;
      border-radius: 0;
      justify-content: center;
      background-color: ${Theme.colors.footer.background.auth};
      padding: 8px 32px;
  `}

  @media (max-width: ${mobileBreakpoint}) {
    align-self: stretch;
    border-radius: 0;
  }
`;

export const WebsiteButtonWrapper = styled.div`
  background-color: #0000005e;
  display: flex;

  justify-content: center;
  padding: 0.5rem;
`;
