import { useUser } from "@/context/UserContext";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import emailValidator from "email-validator";

export const useSignin = () => {
  const { signIn } = useUser();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showSubscriptionsEndedDialog, setShowSubscriptionsEndedDialog] =
    useState(false);
  const toggleSubscriptionsEndedDialog = () =>
    setShowSubscriptionsEndedDialog(!showSubscriptionsEndedDialog);

  const emailError = useMemo(() => {
    const valid = emailValidator.validate(email);

    if (!valid && email.length > 0) {
      return "Formato de e-mail inválido";
    }

    return "";
  }, [email]);

  const onClickForgotPassword = () => {
    navigate("/forgot-password");
  };

  const onClickSignIn = async () => {
    try {
      setIsLoading(true);
      if (!email || !password) {
        return toast("Preencha todos os campos", { type: "error" });
      }

      if (emailError) {
        return;
      }

      await signIn(email, password);
    } catch (err: any) {
      console.error("Error signing-in", err);
      if (err?.response?.status === 403) {
        toggleSubscriptionsEndedDialog();
      } else {
        toast("Erro ao fazer login. Verifique seu e-mail e senha", {
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClickSignUp = () => {
    navigate("/sign-up");
  };

  return {
    email,
    setEmail,
    emailError,
    password,
    setPassword,
    onClickForgotPassword,
    onClickSignIn,
    isLoading,
    onClickSignUp,

    showSubscriptionsEndedDialog,
    toggleSubscriptionsEndedDialog,
  };
};
