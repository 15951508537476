import {
  MediaType,
  UserChallengeStatus,
} from "@/services/challenges/types/challenges.enums";
import Theme from "@/utils/theme";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChallengeSubmissionWrapperProps } from "./props.type";
import { useGetChallengeDetails } from "./hooks/useGetChallengeDetails";

export const useChallengeSubmissionWrapper = ({
  type = "media",
  onClickBackOverwrite,
  onLoadChallenge,
}: Partial<ChallengeSubmissionWrapperProps>) => {
  const { id } = useParams();
  const { data: challenge } = useGetChallengeDetails({
    type,
    id: Number(id),
    onSuccess: onLoadChallenge,
  });

  const navigate = useNavigate();

  const [isFullscreenImage, setIsFullscreenImage] = useState(false);
  const toggleFullscreen = () => setIsFullscreenImage(!isFullscreenImage);

  const challengeStatus = useMemo(() => {
    if (challenge?.status === UserChallengeStatus.APPROVED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Aprovado",
      };
    }

    if (challenge?.status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Finalizado",
      };
    }

    if (challenge?.status === UserChallengeStatus.SENT) {
      return {
        color: Theme.colors.feedback.info,
        text: "Enviado",
      };
    }

    if (challenge?.status === UserChallengeStatus.REJECTED) {
      return {
        color: Theme.colors.feedback.negative,
        text: "Reprovado",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Pendente",
    };
  }, [challenge?.status]);

  const cardTitle = useMemo(() => {
    if (type === "quiz") {
      return "Responder Quiz";
    }

    if (challenge?.tasks?.[0]?.mediaType === MediaType.VIDEO) {
      return "Enviar Vídeo";
    }

    return "Enviar Imagem";
  }, [type, challenge?.tasks]);

  const onClickBack = () => {
    if (onClickBackOverwrite) {
      return onClickBackOverwrite();
    }

    navigateToChallenges();
  };

  const navigateToChallenges = () => {
    navigate("/challenges");
  };

  return {
    challenge,
    challengeStatus,

    isFullscreenImage,
    toggleFullscreen,
    cardTitle,

    onClickBack,
  };
};
