import { getCompletedChallenges } from "@/services/challenges";
import { useMemo } from "react";
import { useQuery } from "react-query";

export function useGetCompletedChallenges() {
  const { data, isLoading } = useQuery("completed-challenges", () =>
    getCompletedChallenges()
  );

  const isCompletedChallenges =
    data?.completedChallenges === data?.totalChallenges;

  const completedChallengesCount = useMemo(() => {
    if (isLoading) return 0;

    return data?.completedChallenges ?? 0;
  }, [isLoading, data]);

  return {
    data,
    completedChallengesCount,
    isCompletedChallenges,
  };
}
