import { memo } from "react";
import Text from "../Text";
import reactStringReplace from "react-string-replace";
import { HighlightedTextProps } from "./props.type";

const HighlightedText = ({
  text,
  highlightColor,
  hightlightWeight,
  ...props
}: HighlightedTextProps) => {
  return (
    <Text display="inline" {...props}>
      {reactStringReplace(text, /{{(.*?)}}/g, (match) => (
        <Text
          display="inline"
          color={highlightColor}
          fontWeight={hightlightWeight}
        >
          {match}
        </Text>
      ))}
    </Text>
  );
};

export default memo(HighlightedText);
