import Spacer from "@/components/Spacer";
import Text from "@/components/Text";
import AuthWrapper from "@/layout/AuthWrapper";
import { Box, CircularProgress } from "@mui/material";
import Button from "@/components/Button";
import TextField from "@/components/TextField";
import { usePasswordRecovery } from "./usePasswordRecovery";
import PasswordInput from "@/components/PasswordInput";
import Dialog from "@/components/Dialog";
import CircleCheck from "@/ui/icons/CircleCheck";
import PdcFooter from "@/components/PdcFooter";

export default function PasswordRecovery() {
  const {
    email,
    code,
    setCode,
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,

    showPassword,
    toggleShowPassword,

    isLoading,
    onSubmit,

    enableResend,
    isLoadingResend,
    onResend,

    showDialog,
    onConfirmDialog,
  } = usePasswordRecovery();

  return (
    <AuthWrapper pageTitle="RECUPERAÇÃO DE SENHA" titleMargin="3rem">
      <Box display="flex" flexDirection="column">
        <Text fontSize="1rem" mt="1rem">
          Crie sua nova senha de acesso:
        </Text>

        <Spacer height="1rem" />
        <TextField label="E-mail" value={email} disabled />

        <Spacer height="1rem" />
        <TextField
          label="Código de confirmação"
          type="text"
          value={code}
          setValue={setCode}
          autoComplete="off"
        />

        <Spacer height="1rem" />
        <PasswordInput
          label="Crie sua senha"
          value={password}
          setValue={setPassword}
          showPassword={showPassword}
          hideIcon
          size="small"
        />

        <Spacer height="1rem" />
        <PasswordInput
          label="Confirme sua senha"
          value={passwordConfirmation}
          setValue={setPasswordConfirmation}
          showPassword={showPassword}
          onToggle={toggleShowPassword}
          size="small"
        />

        <Box mt="1rem" mb="1.5rem" pl="0.5rem">
          <Text color="#484D4B" fontWeight={300}>
            • Ter pelo menos 8 caracteres.
          </Text>
          <Text color="#484D4B" fontWeight={300}>
            • Letras maiúscula e minúscula.
          </Text>
          <Text color="#484D4B" fontWeight={300}>
            • Pelo menos 1 número.
          </Text>
          <Text color="#484D4B" fontWeight={300}>
            • Pelo menos 1 caractere especial.
          </Text>
        </Box>

        <Button size="large" onClick={onSubmit}>
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            "Criar Nova Senha"
          )}
        </Button>

        <Spacer height="1.5rem" />
        <Button
          size="large"
          onClick={onResend}
          disabled={!enableResend}
          variant="text"
        >
          {isLoadingResend ? (
            <CircularProgress color="primary" size="1rem" />
          ) : (
            "Reenviar Código"
          )}
        </Button>
      </Box>

      <Dialog
        isOpen={showDialog}
        icon={<CircleCheck color="#107048" />}
        text="Um novo código para redefinição de senha foi enviado para o seu e-mail."
        confirmButtonText="Entendi"
        onConfirm={onConfirmDialog}
        footer={
          <PdcFooter text={`E-mail de recuperação\nenviado através do:`} />
        }
      />
    </AuthWrapper>
  );
}
