import Theme from "@/utils/theme";
import { Button, ButtonProps, ThemeProvider, createTheme } from "@mui/material";

// TODO: change style to be more generic
const DefaultButton = (props: ButtonProps) => {
  return (
    <Button
      variant={props.variant}
      fullWidth
      {...props}
      style={{
        textTransform: "none",
        fontWeight: 400,
        fontSize: "0.87rem",
        borderRadius: "0.5rem",
        padding: "0.9rem 1rem",
        ...props.style,
      }}
    />
  );
};

export const ButtonWrapper = (props: ButtonProps) => {
  if (props.variant === "outlined") {
    return <OutlinedButton {...props} />;
  }

  if (props.variant === "text") {
    return <TextButton {...props} />;
  }

  return <ContainedButton {...props} />;
};

const ContainedButton = (props: ButtonProps) => {
  let theme = createTheme({
    palette: {
      primary: { main: Theme.colors.button.contained.primary.main },
      secondary: {
        main: Theme.colors.button.contained.secondary.main,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color:
              props.color === "secondary"
                ? Theme.colors.button.contained.secondary.text
                : Theme.colors.button.contained.primary.text,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <DefaultButton {...props} />
    </ThemeProvider>
  );
};

const OutlinedButton = (props: ButtonProps) => {
  let theme = createTheme({
    palette: {
      primary: { main: Theme.colors.button.outlined.primary },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: Theme.colors.button.text,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <DefaultButton {...props} />
    </ThemeProvider>
  );
};

const TextButton = (props: ButtonProps) => {
  let theme = createTheme({
    palette: {
      primary: { main: Theme.colors.button.text },
      error: { main: Theme.colors.feedback.negative },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <DefaultButton {...props} />
    </ThemeProvider>
  );
};
