import styled from "styled-components";
import { SpacerProps } from "./props.type";

export const Container = styled.div<SpacerProps>`
  ${({ grow, width, height }) =>
    grow
      ? `
    flex: 1;
  `
      : `
      height: ${height};
      width: ${width};
      `}
`;
