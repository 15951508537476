import { memo } from "react";
import { CardButtonProps } from "./props.type";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChevronRight from "@/ui/icons/ChevronRight";
import Theme from "@/utils/theme";
import { useCardButton } from "./useCardButton";

const CardButton = ({
  onClick,
  lockButton,
  isPending,
  courseEnded,
}: CardButtonProps) => {
  const { buttonText, buttonVariant } = useCardButton({
    lockButton,
    isPending,
    courseEnded,
  });

  return (
    <Button
      variant={buttonVariant}
      disabled={lockButton || courseEnded}
      onClick={onClick}
      fullWidth={false}
      style={{
        height: "2rem",
        minWidth: "10rem",
        fontSize: "1rem",
      }}
    >
      {buttonText}

      {!lockButton && isPending && !courseEnded && (
        <>
          <Spacer width="0.5rem" />

          <ChevronRight color={Theme.colors.button.contained.primary.text} />
        </>
      )}
    </Button>
  );
};

export default memo(CardButton);
