import { memo } from "react";
import { Container } from "./styles";
import { IconButtonProps } from "./props.type";
import { useIconButton } from "./hooks/useIconButton";
import Theme from "@/utils/theme";

const IconButton = ({
  icon,
  color = Theme.colors.icon.default,
  ...props
}: IconButtonProps) => {
  const { Icon } = useIconButton({ icon, color, ...props });

  return <Container {...props}>{Icon}</Container>;
};

export default memo(IconButton);
