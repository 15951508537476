import styled from "styled-components";

export const Container = styled.div<{ width?: string }>`
  display: flex;
  flex: 1;

  width: ${({ width }) => width};
`;

export const Content = styled.div`
  z-index: 10;
  display: flex;
  background-color: white;
  flex: 1;
  margin: 0px -0.1rem;
`;

export const ShapeImage = styled.img<{ isLeft?: boolean }>`
  height: 100%;

  ${({ isLeft }) =>
    isLeft
      ? `
      margin-right: -0.1rem
  `
      : `
      margin-left: -0.1rem
      `}
`;
