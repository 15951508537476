import { memo } from "react";
import { ModalProps } from "./props.type";
import { ModalContent } from "./styles";
import { Modal as MUIModal } from "@mui/material";

const Modal = ({ isOpen, onClose, children, containerProps }: ModalProps) => {
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <ModalContent {...containerProps}>{children}</ModalContent>
    </MUIModal>
  );
};

export default memo(Modal);
