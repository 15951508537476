import { TaskImageInputProps } from "./props.type";
import { Box, CircularProgress } from "@mui/material";
import Theme from "@/utils/theme";
import { Container } from "./styles";
import Text from "@/components/Text";
import { FileInput } from "@/components/FileInput";
import { getUploadMediaChallengeImageUrl } from "@/services/challenges/mediaChallenges";
import { useState } from "react";
import UploadIcon from "@/ui/icons/UploadIcon";
import { ChallengeTooltip } from "../ChallengeTooltip";

const MAX_MB_SIZE = 10;

export const TaskImageInput = ({
  disabled,
  setImageUrl,
  taskId,
  showTooltip,
  onCloseTooltip,
}: TaskImageInputProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box position="relative">
      <ChallengeTooltip
        isOpen={showTooltip}
        onClose={onCloseTooltip}
        placement="top-start"
        title="Comece inserindo uma foto seguindo as informações do tutorial acima. Clique no botão abaixo para escolher a melhor foto."
        containerProps={{
          position: "absolute",
          top: { xs: "-4rem", sm: "3rem" },
          left: { xs: 0, sm: "60%" },
        }}
      />

      <FileInput
        component={
          <Container>
            {isLoading ? (
              <CircularProgress
                style={{ color: Theme.colors.feedback.info }}
                size="1.5rem"
              />
            ) : (
              <UploadIcon color={Theme.colors.feedback.info} />
            )}
            <Text
              align="center"
              color={Theme.colors.feedback.info}
              fontWeight={300}
              my="0.5rem"
            >
              Clique para enviar{"\n"}uma foto
            </Text>
            <Text
              align="center"
              color={Theme.colors.text.secondary}
              fontWeight={300}
            >
              JPG ou PNG (Máx. {MAX_MB_SIZE}MB)
            </Text>
          </Container>
        }
        disabled={disabled}
        getSignedUrlService={() => getUploadMediaChallengeImageUrl(taskId)}
        onUploaded={setImageUrl}
        setIsLoading={setIsLoading}
        maxMbSize={MAX_MB_SIZE}
      />
    </Box>
  );
};
