import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

import { ArrowBack } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material";

const ArrowBackIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  const theme = createTheme({
    palette: { primary: { main: color } },
  });

  return (
    <ThemeProvider theme={theme}>
      <ArrowBack style={{ width: size, height: size }} color="primary" />
    </ThemeProvider>
  );
};

export default memo(ArrowBackIcon);
