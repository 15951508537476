import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { Card, Stack } from "@mui/material";
import HighlightedText from "../HighlightedText";
import { SectionDescriptionProps } from "./props.type";

export default function SectionDescription({
  title,
  description,
}: SectionDescriptionProps) {
  return (
    <Card
      variant="outlined"
      style={{
        marginBottom: "1rem",
        borderRadius: "0.5rem",
        backgroundColor: Theme.colors.background.secondary,
      }}
    >
      <Stack p="2rem">
        <Text
          mb="0.5rem"
          color={Theme.colors.brand.secondary}
          fontWeight={900}
          fontSize="1.5rem"
        >
          {title}
        </Text>

        <HighlightedText
          fontWeight={500}
          highlightColor={Theme.colors.brand.secondary}
          hightlightWeight={900}
          text={description}
          color={Theme.colors.sidebar.text.default}
        />
      </Stack>
    </Card>
  );
}
