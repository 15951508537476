import AccordionDetailItem from "../AccordionDetailItem";
import { Box, CircularProgress } from "@mui/material";
import { AccordionDetailsListProps } from "./props.type";
import { useAccordionDetailsList } from "./useAccordionDetailsList";

function AccordionDetailsList(props: AccordionDetailsListProps) {
  const { challenges, isLoading } = useAccordionDetailsList(props);

  return (
    <>
      {isLoading && (
        <Box display="flex" justifyContent="center" p="1rem">
          <CircularProgress size="1rem" />
        </Box>
      )}

      {challenges?.map((item) => (
        <AccordionDetailItem
          stageId={props.stageId}
          key={item.title}
          challenge={item}
        />
      ))}
    </>
  );
}

export default AccordionDetailsList;
