import Text from "@/components/Text";
import { Box } from "@mui/material";
import Theme from "@/utils/theme";
import { AuthWrapperProps } from "./props.type";
import PdcFooter from "../../components/PdcFooter";
import { RoundedButton, ScrollButton } from "./styles";
import Spacer from "@/components/Spacer";
import DownArrowCircle from "@/ui/icons/DownArrowCircle";
import { getStorageImageUrl } from "@/utils/get-storage-image-url";
import WestIcon from "@mui/icons-material/West";

export default function AuthWrapper({
  pageTitle,
  titleMargin,
  onClickBack,
  useSignupImages,
  children,
}: AuthWrapperProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={{ xs: "1rem", sm: "2.5rem" }}
      flex={1}
      bgcolor={Theme.colors.background.auth}
    >
      <Text
        fontFamily="Montserrat"
        fontSize="2rem"
        color={Theme.colors.brand.secondary}
        style={{ borderBottom: `2px solid ${Theme.colors.brand.primary}` }}
        width="fit-content"
        mb="1.5rem"
        alignSelf="center"
        textAlign="center"
      >
        {pageTitle}
      </Text>

      <Box display={{ xs: "none", sm: "flex" }}>
        <Spacer height={titleMargin} />
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ sm: "center" }}
        mb="2.5rem"
      >
        {/* Desktop image */}
        <Box
          display={{ xs: "none", sm: "flex" }}
          flex={0.58}
          alignItems="center"
          justifyContent="center"
        >
          <Box position="relative">
            <img
              src={getStorageImageUrl(
                useSignupImages ? "signup_desktop.svg" : "signin_desktop.svg"
              )}
              alt=""
              height={'488px'}
              width={'723.5px'}
            />
          </Box>
        </Box>

        {/* Mobile image */}
        <Box display={{ xs: "flex", sm: "none" }} flex={1}>
          <Box width="100%" mb="-1rem">
            <img
              src={getStorageImageUrl(
                useSignupImages ? "signup_mobile.svg" : "signin_mobile.svg"
              )}
              style={{ width: "90%" }}
            />
          </Box>
        </Box>

        <Box display="flex" flex={{ xs: 1, sm: 0.42 }}>
          <Box
            width={{ xs: "100%", sm: "85%" }}
            ml={{ xs: "0px", sm: "2rem" }}
            borderRadius="1rem"
            border={`1px solid ${Theme.colors.neutral.mid}`}
            boxShadow="0px 1rem 2rem 0.2rem rgba(0,0,0,0.08)"
            height="fit-content"
            bgcolor={Theme.colors.neutral.lightest}
          >
            <Box
              display="flex"
              bgcolor={Theme.colors.brand.secondary}
              borderRadius="1rem 1rem 0px 0px"
              py="0.5rem"
              justifyContent="center"
              position="relative"
            >
              {onClickBack && (
                <RoundedButton onClick={onClickBack}>
                  <WestIcon fontSize="large" />
                </RoundedButton>
              )}

              <Box display={{ xs: "flex", sm: "none" }}>
                <ScrollButton>
                  <DownArrowCircle />
                </ScrollButton>
              </Box>
              <img
                src={getStorageImageUrl("logo_auth.png")}
                style={{ width: "9rem", margin: "0.5rem 0px" }}
                alt="Logo"
              />
            </Box>

            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              py="1.5rem"
              px="2rem"
            >
              {children}
            </Box>

            <PdcFooter overflow="hidden" borderRadius="1rem" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
