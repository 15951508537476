import "./instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "@/index.css";
import * as Sentry from "@sentry/react";

ReactDOM.createRoot(document.getElementById("root")!, {
  onRecoverableError: Sentry.reactErrorHandler(),
}).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
