import { memo } from "react";
import Select from "../Select";
import { MenuItem } from "@mui/material";
import { Gender } from "@/services/auth/types/auth.types";
import { getGenderText } from "@/utils/get-gender-text";
import { CustomTextFieldProps } from "../TextField/props.type";

const GenderSelect = (props: CustomTextFieldProps) => {
  return (
    <Select label="Gênero" {...props}>
      <MenuItem value={Gender.MALE}>{getGenderText(Gender.MALE)}</MenuItem>
      <MenuItem value={Gender.FEMALE}>{getGenderText(Gender.FEMALE)}</MenuItem>
      <MenuItem value={Gender.OTHER}>{getGenderText(Gender.OTHER)}</MenuItem>
    </Select>
  );
};

export default memo(GenderSelect);
