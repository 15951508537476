import styled from "styled-components";

export const UnskewWrapper = styled.div`
  transform: skew(30deg);
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    transform: skew(10deg);
  }
`;

export const SummaryWrapper = styled.div`
  transform: skew(30deg);
  display: flex;
  flex: 1;
  padding: 0px 1rem;

  @media (max-width: 600px) {
    transform: skew(10deg);
  }
`;
