import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;

  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-bottom: 2rem;
`;

export const PageContent = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 950px;
      margin: 0 auto;
    `}

  padding: 1.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1rem 0px;
    width: auto;
  }
`;
