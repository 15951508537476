import { changeUserPassword, sendPasswordRecoveryCode } from "@/services/auth";
import { passwordRegex } from "@/utils/regex";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export const usePasswordRecovery = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [email] = useState(params.get("email"));

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);

  const [enableResend, setEnableResend] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const toggleDialog = () => setShowDialog(!showDialog);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (!code || !password || !passwordConfirmation) {
        return toast("Preencha todos os campos!", { type: "error" });
      }

      if (!email) {
        return toast("Erro ao encontrar email, tente novamente", {
          type: "error",
        });
      }

      if (password !== passwordConfirmation) {
        return toast("As senhas devem ser iguais!", { type: "error" });
      }

      if (!password.match(passwordRegex)) {
        toast("A senha deve seguir as regras definidas abaixo.", {
          type: "error",
        });
        return;
      }
      await changeUserPassword(email, code, password);

      toast("Senha alterada com sucesso!", { type: "success" });
      navigate("/sign-in");
    } catch (err) {
      console.error("Error recovering password", err);
      toast("Erro ao alterar senha", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onResend = async () => {
    try {
      setIsLoadingResend(true);
      if (!email) {
        return toast("Erro ao encontrar email, tente novamente", {
          type: "error",
        });
      }

      await sendPasswordRecoveryCode(email);
      toggleDialog();
      setEnableResend(false);
      setTimerForResend();
    } catch (err) {
      console.error("Error sending password recovery email", err);
      toast("Erro ao enviar email de recuperação", { type: "error" });
    } finally {
      setIsLoadingResend(false);
    }
  };

  const onConfirmDialog = () => {
    setShowDialog(false);
  };

  const setTimerForResend = () => {
    setTimeout(() => {
      setEnableResend(true);
    }, 60 * 1000);
  };

  useEffect(() => {
    setTimerForResend();
  }, []);

  return {
    email,
    code,
    setCode,
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,

    showPassword,
    toggleShowPassword,

    isLoading,
    onSubmit,

    enableResend,
    isLoadingResend,
    onResend,

    showDialog,
    onConfirmDialog,
  };
};
