import { useEffect, useMemo, useState } from "react";
import { ShapedAccordionProps } from "./props.type";

export const useShapedAccordion = ({
  items,
  fixedIndex,
  onChangeExpanded,
  indexToClose = -1,
  defaultIndexesKey,
}: Partial<ShapedAccordionProps>) => {
  const [userExpandedIndexes, setUserExpandedIndexes] = useState<{
    [index: string]: boolean | undefined;
  }>({});
  const [defaultExpandedIndexes, setDefaultExpandedIndexes] = useState<
    number[]
  >([]);
  const [currentDefaultsKey, setCurrentDefaultsKey] =
    useState(defaultIndexesKey);
  const [shouldCloseIndex, setShouldCloseIndex] = useState(false);

  const handleChange = (index: number) => (_: any, shouldExpand: boolean) => {
    if (shouldExpand) {
      setUserExpandedIndexes({ ...userExpandedIndexes, [index]: true });
    } else {
      setUserExpandedIndexes({ ...userExpandedIndexes, [index]: false });
    }
  };

  const getDefaultExpandedIndexes = () => {
    if (!items?.length) return [];

    const aux: number[] = [];
    items.forEach((item, index) => {
      if (item.defaultExpanded) {
        aux.push(index);
      }
    });

    if (fixedIndex !== undefined) {
      aux.push(fixedIndex);
    }

    return [...new Set(aux)];
  };

  const expandedIndexes = useMemo(() => {
    let aux: number[] = getDefaultExpandedIndexes();

    Object.keys(userExpandedIndexes).forEach((key) => {
      const index = Number(key);
      if (userExpandedIndexes[key]) {
        aux.push(index);
      } else if (userExpandedIndexes[key] === false) {
        aux = aux.filter((i) => i !== index);
      }
    });

    if (fixedIndex !== undefined) aux.push(fixedIndex);

    return [...new Set(aux)];
  }, [userExpandedIndexes, fixedIndex, items]);

  const getExpandedValue = (index: number) => {
    if (index === fixedIndex) {
      return true;
    }

    return expandedIndexes.includes(index);
  };

  useEffect(() => {
    if (!fixedIndex) return onChangeExpanded?.([...expandedIndexes]);

    onChangeExpanded?.([...expandedIndexes, fixedIndex]);
  }, [expandedIndexes, fixedIndex]);

  const finalIndexToClose = useMemo(() => {
    if (shouldCloseIndex) {
      return indexToClose;
    }

    return -1;
  }, [shouldCloseIndex, indexToClose]);

  useEffect(() => {
    const aux = getDefaultExpandedIndexes();
    const isSameIndexes =
      aux.length === defaultExpandedIndexes.length &&
      aux.every((value, index) => value === defaultExpandedIndexes[index]);
    let shouldUpdateObject = false;

    const newObject = userExpandedIndexes;

    if (!isSameIndexes || currentDefaultsKey !== defaultIndexesKey) {
      for (const index of aux) {
        newObject[index] = undefined;
        shouldUpdateObject = true;
      }
      setCurrentDefaultsKey(defaultIndexesKey);
    }

    if (finalIndexToClose >= 0) {
      if (newObject[finalIndexToClose]) {
        newObject[finalIndexToClose] = undefined;
        shouldUpdateObject = true;
      }
      setShouldCloseIndex(false);
    }

    if (shouldUpdateObject) {
      setUserExpandedIndexes({
        ...newObject,
      });
    }
    setDefaultExpandedIndexes(aux);
  }, [items, currentDefaultsKey, defaultIndexesKey, finalIndexToClose]);

  useEffect(() => {
    if (indexToClose >= 0) {
      setShouldCloseIndex(true);
    }
  }, [indexToClose]);

  return { handleChange, getExpandedValue };
};
