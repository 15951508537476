import Sidebar from "@/layout/PageWrapper/components/Sidebar";
import { Container, Content, PageContent } from "./styles";
import Toolbar from "./components/Toolbar";
import { usePageWrapper } from "./hooks/usePageWrapper";
import FooterWrapper from "./components/FooterWrapper";
import { useLocation } from "react-router-dom";

const noPageWrapperRoutes = ["/profile"];

export const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { hideWrapper, sidebarButtons, showWebsiteButton } = usePageWrapper();
  const location = useLocation();

  if (hideWrapper) {
    return (
      <FooterWrapper variant="auth" showWebsiteButton={showWebsiteButton}>
        {children}
      </FooterWrapper>
    );
  }

  const fullWidth = !noPageWrapperRoutes.includes(location.pathname);

  return (
    <Container>
      <Sidebar buttons={sidebarButtons} />
      <FooterWrapper showWebsiteButton={showWebsiteButton}>
        <Content>
          <Toolbar buttons={sidebarButtons} />

          <PageContent fullWidth={fullWidth}>{children}</PageContent>
        </Content>
      </FooterWrapper>
    </Container>
  );
};
