import Theme from "@/utils/theme";
import { IconButton, IconButtonProps } from "@mui/material";

export const Container = (props: IconButtonProps) => (
  <IconButton
    style={{
      border: `1px solid ${Theme.colors.neutral.mid}`,
      aspectRatio: "1/1",
    }}
    sx={{
      "&.Mui-disabled": {
        background: Theme.colors.neutral.light,
      },
    }}
    {...props}
  />
);
