import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";
import styled from "styled-components";
import FullscreenIcon from "/assets/icons/fullscreen.svg";

export const DashedContainer = (props: BoxProps) => (
  <Box
    display="flex"
    flex={1}
    border={`1px dashed ${Theme.colors.neutral.mid}`}
    justifyContent="space-between"
    alignItems="center"
    borderRadius="0.5rem"
    p="0.5rem"
    {...props}
  />
);

export const TaskImage = styled.img`
  aspect-ratio: 1/1;
  width: 4rem;
  border-radius: 0.5rem;
  object-fit: cover;
`;

export const TaskVideo = styled.video`
  aspect-ratio: 1/1;
  width: 4rem;
  border-radius: 0.5rem;
  object-fit: cover;
`;

export const FullscreenButton = styled.img.attrs({
  src: FullscreenIcon,
})`
  z-index: 10;
  position: absolute;
  right: 1rem;
  bottom: 1rem;

  cursor: pointer;
`;
