import { createContext, useEffect, useMemo, useState } from "react";
import { useUser } from "./UserContext";
import Dialog from "@/components/Dialog";
import SadFaceIcon from "/assets/icons/sad-face.svg";
import { noAuthRequired } from "./ProtectRoutesContext";
import { useLocation, useNavigate } from "react-router-dom";
import PartyIcon from "/assets/icons/party.svg";
import { useStages } from "./StagesContext";

type SessionDialogsContext = {};

const SessionDialogsContext = createContext<SessionDialogsContext>(
  {} as SessionDialogsContext
);

type SessionDialogsProps = {
  children: React.ReactNode;
};

export const SessionDialogsProvider = ({ children }: SessionDialogsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { currentStage, currentStageNumber, isStageClosed, isLoading } =
    useStages();

  const [isOpenInvalidStageDialog, setIsOpenInvalidStageDialog] =
    useState(false);
  const [isOpenFinalStageDialog, setIsOpenFinalStageDialog] = useState(false);
  const [isOpenCheckProfileDialog, setIsOpenCheckProfileDialog] =
    useState(false);

  const isAuthenticatedPage = useMemo(() => {
    return !noAuthRequired.includes(location.pathname);
  }, [location.pathname]);

  // did not advance stage dialog
  useEffect(() => {
    if (
      !userInfo?.cognitoUserId ||
      !isStageClosed ||
      isLoading ||
      !isAuthenticatedPage
    ) {
      return;
    }

    const invalidStageDialogUserId = localStorage.getItem(
      "invalidStageDialogUserId"
    );

    if (invalidStageDialogUserId !== userInfo.cognitoUserId) {
      setIsOpenInvalidStageDialog(true);
      localStorage.setItem("invalidStageDialogUserId", userInfo.cognitoUserId);
    }
  }, [isLoading, isStageClosed, userInfo?.cognitoUserId, isAuthenticatedPage]);

  // final stage dialog
  useEffect(() => {
    if (
      !userInfo?.cognitoUserId ||
      !currentStage?.isFinalStage ||
      isLoading ||
      !isAuthenticatedPage
    ) {
      return;
    }

    const finalStageDialogUserId = localStorage.getItem(
      "finalStageDialogUserId"
    );

    if (finalStageDialogUserId !== userInfo.cognitoUserId) {
      setIsOpenFinalStageDialog(true);
      localStorage.setItem("finalStageDialogUserId", userInfo.cognitoUserId);
    }
  }, [
    isLoading,
    currentStage?.isFinalStage,
    userInfo?.cognitoUserId,
    isAuthenticatedPage,
  ]);

  // complete profile dialog
  useEffect(() => {
    if (
      !userInfo?.cognitoUserId ||
      currentStageNumber > 1 ||
      isLoading ||
      !isAuthenticatedPage ||
      isStageClosed
    ) {
      return;
    }

    const completeProfileDialogUserId = localStorage.getItem(
      "completeProfileDialogUserId"
    );

    if (completeProfileDialogUserId !== userInfo.cognitoUserId) {
      setIsOpenCheckProfileDialog(true);
      localStorage.setItem(
        "completeProfileDialogUserId",
        userInfo.cognitoUserId
      );
    }
  }, [
    isLoading,
    currentStageNumber,
    userInfo?.cognitoUserId,
    isAuthenticatedPage,
    isStageClosed,
  ]);

  return (
    <SessionDialogsContext.Provider value={{}}>
      {children}
      <Dialog
        isOpen={isOpenInvalidStageDialog}
        icon={<img src={SadFaceIcon} />}
        text="Infelizmente não foi dessa vez. Você não avançou para a próxima etapa. Mas não deixe de tentar na próxima edição."
        onConfirm={() => setIsOpenInvalidStageDialog(false)}
      />
      <Dialog
        isOpen={isOpenFinalStageDialog}
        icon={<img src={PartyIcon} />}
        text="Parabéns! Você avançou para a etapa final."
        onConfirm={() => setIsOpenFinalStageDialog(false)}
      />
      <Dialog
        isOpen={isOpenCheckProfileDialog}
        text="Importante: Confirme seus dados. Estamos chegando no fim da primeira etapa, verifique se todos os seus dados estão corretos."
        confirmButtonText="Confirmar dados"
        onConfirm={() => {
          navigate("/profile");
          setIsOpenCheckProfileDialog(false);
        }}
      />
    </SessionDialogsContext.Provider>
  );
};
