import Theme from "@/utils/theme";

export type IconProps = {
  size?: number;
  color?: string;
};

export const defaultIconProps: Required<IconProps> = {
  size: 24,
  color: Theme.colors.icon.default,
};
