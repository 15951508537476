import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";

export const TableWrapper = (props: BoxProps) => (
  <Box
    mt="1rem"
    display="flex"
    flex={1}
    flexDirection="column"
    border={`1px solid ${Theme.colors.neutral.light}`}
    borderRadius="0.5rem"
    {...props}
  />
);

export const TableRow = (props: BoxProps) => (
  <Box display="flex" flexDirection="row" flex={1} p="0.5rem 0rem" {...props} />
);

export const TableRowHeader = (props: BoxProps) => (
  <TableRow bgcolor={Theme.colors.regulation?.brand_light} {...props} />
);

export const TableRowItem = (props: BoxProps) => (
  <Box display="flex" flex={1} ml="1.5rem" {...props} />
);
