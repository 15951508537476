import { getMediaChallengeInfo } from "@/services/challenges/mediaChallenges";
import { getQuizChallengeInfo } from "@/services/challenges/quizChallenges";
import { ChallengeType } from "@/services/challenges/types/challenges.types";
import { useQuery } from "react-query";

type useGetChallengeDetailsProps = {
  type?: "quiz" | "media";
  id?: number;
  onSuccess?: (item?: ChallengeType | undefined) => void;
};

export function useGetChallengeDetails({
  type = "media",
  id,
  onSuccess,
}: useGetChallengeDetailsProps) {
  return useQuery(
    ["challenge-details", id],
    () => {
      if (type === "quiz") {
        return getQuizChallengeInfo(id!);
      }

      return getMediaChallengeInfo(id!);
    },
    { enabled: !!id, onSuccess }
  );
}
