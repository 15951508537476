import { useSignup } from "./hooks/useSignup";
import Text from "@/components/Text";
import EmailDialog from "./components/EmailDialog";
import { Box, CircularProgress, InputAdornment } from "@mui/material";
import Spacer from "@/components/Spacer";
import TextField from "@/components/TextField";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import { Gender } from "@/services/auth/types/auth.types";
import PasswordInput from "@/components/PasswordInput";
import PhoneInput from "@/components/PhoneInput";
import GenderSelect from "@/components/GenderSelect";
import StateSelect from "@/components/StateSelect";
import CitySelect from "@/components/CitySelect";
import Dialog from "@/components/Dialog";
import AuthWrapper from "@/layout/AuthWrapper";
import DateInput from "@/components/DateInput";
import Divider from "@/components/Divider";
import Theme from "@/utils/theme";
import CircleCheck from "@/ui/icons/CircleCheck";
import MaskedInput from "@/components/MaskedInput";
import { useReality } from "@/context/RealityContext";
import { realityInfo } from "@/utils/realityInfo";
import SignupStepper from "./components/SignupStepper";
import { getTermsOfUseFile } from "@/utils/get-terms-of-use-file";
import { Tooltip } from "@/components/Tooltip";
import InfoIcon from "@/ui/icons/InfoIcon";

export default function SignUp() {
  const {
    isOpenEmailDialog,
    isOpenAlreadyRegistered,
    isOpenAlreadyPdc,
    isOpenSignUp,
    isSignUpSuccess,
    successModalText,
    goBack,
    goToSignIn,
    onValidateEmail,
    closeSignupDialog,
    isLoadingButton,
    currentStep,
    setCurrentStep,

    email,
    setEmail,
    name,
    setName,
    nameError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    gender,
    setGender,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    showPassword,
    toggleShowPassword,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,

    confirmWorker,
    setConfirmWorker,
    confirmTerms,
    setConfirmTerms,

    resaleCode,
    setResaleCode,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,

    buttonText,
    onPressButton,
  } = useSignup();
  const { isSubscriptionOpen } = useReality();

  return (
    <AuthWrapper
      pageTitle="INSCREVA-SE AQUI!"
      useSignupImages
      onClickBack={
        currentStep > 1 ? () => setCurrentStep(currentStep - 1) : undefined
      }
    >
      <SignupStepper currentStep={currentStep - 1} />

      {/* First step: user info */}
      <Box display={currentStep === 1 ? "block" : "none"}>
        {realityInfo.showResaleCode ? (
          <Text fontSize="1rem" mt="1rem">
            Passo 1 - Preencha seus dados para a inscrição:
          </Text>
        ) : (
          <Text fontSize="1rem" mt="1rem">
            Preencha seus dados para a inscrição:
          </Text>
        )}

        <Spacer height="1.5rem" />
        <TextField label="E-mail" value={email} />

        <Spacer height="1rem" />
        <TextField
          label="Nome completo"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!nameError}
          helperText={nameError}
        />

        <Spacer height="1rem" />
        <Box display="flex" flex={1}>
          <PhoneInput
            label="Telefone (WhatsApp)"
            value={phoneNumber}
            setValue={setPhoneNumber}
            error={!!phoneNumberError}
            errorText={phoneNumberError}
            ContainerProps={{ flex: 1 }}
          />

          <Spacer width="1rem" />

          <DateInput
            label="Data Nascimento"
            value={birthDate}
            setValue={setBirthDate}
            error={!!birthDateError}
            errorText={birthDateError}
            ContainerProps={{ flex: 1 }}
          />
        </Box>
        <Spacer height="1rem" />
        <Box display="flex" flex={1}>
          <GenderSelect
            value={gender}
            onChange={(e) => setGender(e.target.value as Gender)}
            ContainerProps={{ flex: 1 }}
          />
          <Spacer width="1rem" />

          <StateSelect
            value={selectedState}
            setValue={setSelectedState}
            ContainerProps={{ flex: 1 }}
          />
        </Box>

        <Spacer height={"1rem"} />
        <CitySelect
          state={selectedState}
          value={selectedCity}
          setValue={setSelectedCity}
          ContainerProps={{ flex: 1 }}
        />

        <Divider margin="1rem 0rem" />

        <PasswordInput
          label="Crie sua senha"
          value={password}
          setValue={setPassword}
          showPassword={showPassword}
          size="small"
          hideIcon
        />

        <Spacer height="1rem" />
        <PasswordInput
          label="Confirme sua senha"
          value={confirmPassword}
          setValue={setConfirmPassword}
          showPassword={showPassword}
          onToggle={toggleShowPassword}
          size="small"
        />

        <Box mt="1rem" pl="0.5rem">
          <Text color="#3B3A33" fontWeight={300}>
            • Ter pelo menos 8 caracteres.
          </Text>
          <Text color="#3B3A33" fontWeight={300} mt="0.5rem">
            • Letras maiúscula e minúscula.
          </Text>
          <Text color="#3B3A33" fontWeight={300} mt="0.5rem">
            • Pelo menos 1 número.
          </Text>
          <Text color="#3B3A33" fontWeight={300} mt="0.5rem">
            • Pelo menos 1 carácter especial.
          </Text>
        </Box>

        <Spacer height="1rem" />
        <Checkbox
          value={confirmWorker}
          onChange={() => setConfirmWorker(!confirmWorker)}
        >
          <Text>{realityInfo.confirmOccupationText}</Text>
        </Checkbox>

        <Checkbox
          value={confirmTerms}
          onChange={() => setConfirmTerms(!confirmTerms)}
        >
          <Text display="inline">
            Estou de acordo com o{" "}
            <a href={getTermsOfUseFile()} target="_blank" rel="noreferrer">
              Termo de Uso
            </a>{" "}
            disponível.
          </Text>
        </Checkbox>
      </Box>

      {/* Second step: resale code */}
      <Box display={currentStep === 2 ? "block" : "none"}>
        <Text fontSize="1rem" mt="1rem">
          Passo 2 - Caso você possua o código da revenda, insira abaixo:
        </Text>

        <Spacer height="1.5rem" />

        <MaskedInput
          label="Código de Revenda"
          value={resaleCode}
          setValue={(val) => setResaleCode(val.toUpperCase())}
          mask="a99999"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  isOpen={isVisibleResaleCodeTooltip}
                  onClose={toggleIsVisibleResaleCodeTooltip}
                  placement={"bottom-end"}
                  title="Este campo não é obrigatório. O Código da Revenda relaciona o seu perfil com uma Loja de Materiais para Construção. Entre em contato com a revenda Quartzolit mais próxima e solicite este código gratuito."
                >
                  <Box
                    display="flex"
                    style={{ cursor: "pointer" }}
                    onClick={toggleIsVisibleResaleCodeTooltip}
                  >
                    <InfoIcon size={20} />
                  </Box>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <Text mt="1rem" color={Theme.colors.feedback.warning} fontSize="1rem">
          *Não é um código obrigatório.
        </Text>
      </Box>
      <Spacer height="1.5rem" />
      <Button
        size="large"
        onClick={() => onPressButton(realityInfo.showResaleCode)}
        disabled={!isSubscriptionOpen || isLoadingButton}
      >
        {isLoadingButton ? (
          <CircularProgress style={{ color: "white" }} size="1rem" />
        ) : (
          buttonText
        )}
      </Button>

      {realityInfo.showResaleCode && currentStep === 2 && (
        <>
          <Spacer height="1rem" />
          <Button
            size="large"
            onClick={() => onPressButton()}
            disabled={!isSubscriptionOpen || isLoadingButton}
            variant="outlined"
          >
            {isLoadingButton ? (
              <CircularProgress style={{ color: "white" }} size="1rem" />
            ) : (
              "Finalizar inscrição sem o código"
            )}
          </Button>
        </>
      )}

      {/* --- Dialogs --- */}
      <EmailDialog
        isOpen={isOpenEmailDialog}
        email={email}
        setEmail={setEmail}
        onConfirm={onValidateEmail}
        onClose={goBack}
        isLoading={isLoadingButton}
      />
      <Dialog
        isOpen={isOpenAlreadyRegistered}
        text={`Este e-mail já possui cadastro!\nClique abaixo para fazer o login.`}
        confirmButtonText="Entrar"
        onConfirm={goToSignIn}
        onClose={goBack}
        closeButtonText="Sair"
      />
      <Dialog
        isOpen={isOpenAlreadyPdc}
        text={`O seu e-mail já está vinculado a uma conta no Parceiro da Construção.\nClique abaixo e faça o login com a mesma credencial utilizada na plataforma.`}
        confirmButtonText="Entrar"
        onConfirm={goToSignIn}
        onClose={goBack}
        closeButtonText="Sair"
      />
      <Dialog
        isOpen={isOpenSignUp}
        text={`E-mail verificado!\nVocê ainda não possui um cadastro. Clique abaixo em "Inscreva-se" para seguir com seu cadastro.`}
        confirmButtonText="Inscreva-se"
        onConfirm={closeSignupDialog}
        onClose={goBack}
        closeButtonText="Sair"
      />
      <Dialog
        isOpen={isSignUpSuccess}
        icon={<CircleCheck size={32} />}
        text={successModalText}
        confirmButtonText="Realizar login"
        onConfirm={() => {
          window.open(window.location.origin + "/sign-in");
        }}
      />
    </AuthWrapper>
  );
}
