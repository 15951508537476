import { useStages } from "@/context/StagesContext";
import { getFormattedDate } from "@/helpers/date.helper";
import { UserChallengeStatus } from "@/services/challenges/types/challenges.enums";
import { ChallengeStageDetails } from "@/services/challenges/types/challenges.types";
import Theme from "@/utils/theme";
import { isBefore } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useAccordionDetailItem = (
  stageId: number,
  {
    id,
    startDate,
    endDate,
    status,
    type,
    isOpenForStart,
  }: ChallengeStageDetails
) => {
  const navigate = useNavigate();
  const { isValidStage, currentStage } = useStages();

  const isStarted = useMemo(() => {
    return isBefore(startDate, new Date()) && isOpenForStart;
  }, [startDate, isOpenForStart]);

  const statusText = useMemo(() => {
    if (type === "InPersonChallenge") {
      if (status === UserChallengeStatus.FINISHED) {
        return {
          color: Theme.colors.feedback.positive,
          text: "Finalizado",
        };
      }

      const toDate = ` à ${getFormattedDate(endDate, true)}`;
      return {
        color: "#F9FAFA",
        textColor: Theme.colors.neutral.mid_dark,
        text: `Dinâmica dia:${"\n"}${getFormattedDate(
          startDate,
          true
        )}${toDate}`,
        type: "in-person",
      };
    }

    if (!isStarted) {
      return {
        color: Theme.colors.neutral.light,
        textColor: Theme.colors.neutral.mid_dark,
        text: `Liberação dia ${getFormattedDate(startDate, true)}`,
      };
    }

    if (status === UserChallengeStatus.PENDING) {
      return {
        color: Theme.colors.feedback.warning,
        text: "Pendente",
      };
    }

    if (status === UserChallengeStatus.APPROVED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Aprovado",
      };
    }

    if (status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Finalizado",
      };
    }

    if (status === UserChallengeStatus.REJECTED) {
      return {
        color: Theme.colors.feedback.negative,
        text: "Reprovado",
      };
    }

    return {
      color: Theme.colors.feedback.info,
      text: "Enviado",
    };
  }, [isStarted, status, type]);

  const buttonType: "locked" | "points" | "waiting" | "pending" | "started" =
    useMemo(() => {
      if (type === "InPersonChallenge") {
        if (status === UserChallengeStatus.FINISHED) {
          return "points";
        }

        return "waiting";
      }

      if (!isStarted) {
        return "locked";
      }

      if (status !== UserChallengeStatus.PENDING) {
        return "started";
      }

      if (currentStage?.id !== stageId || !isValidStage) {
        return "locked";
      }

      return "pending";
    }, [type, isValidStage, isStarted, status, currentStage?.id, stageId]);

  const handleStartChallenge = () => {
    const queryType = type === "QuizChallenge" ? "quiz" : "media";
    navigate(`/challenges/${queryType}/${id}`);
  };

  return { statusText, buttonType, handleStartChallenge };
};
