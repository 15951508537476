import PhoneInput from "@/components/PhoneInput";
import TextField from "@/components/TextField";
import { CustomTextFieldProps } from "@/components/TextField/props.type";
import { CustomInputVariant } from "@/services/challenges/types/challenges.types";
import styled from "styled-components";

export const Image = styled.img`
  height: 15.5rem;
  width: 25.5rem;
  object-fit: contain;

  margin-bottom: 1.5rem;
`;

export const Video = styled.video`
  height: 15.5rem;
  width: 25.5rem;
  object-fit: contain;

  margin-bottom: 1.5rem;
`;

export const CustomInputField = ({
  variant,
  ...props
}: { variant: CustomInputVariant } & Omit<CustomTextFieldProps, "variant">) => {
  if (variant === "singleline") {
    return <TextField {...props} />;
  }

  if (variant === "phonenumber") {
    return (
      <PhoneInput
        label={(props.label as string) ?? props.placeholder}
        value={props.value as string}
        setValue={props.setValue}
        disabled={props.disabled}
        error={props.error}
        errorText={props.helperText as string}
      />
    );
  }

  return <TextField multiline rows={5} {...props} />;
};
