import { TaskVideoInputProps } from "./props.type";
import { Box, CircularProgress } from "@mui/material";
import Theme from "@/utils/theme";
import { Container } from "./styles";
import Text from "@/components/Text";
import { FileInput } from "@/components/FileInput";
import { getUploadMediaChallengeVideoUrl } from "@/services/challenges/mediaChallenges";
import { useState } from "react";
import UploadIcon from "@/ui/icons/UploadIcon";
import { ChallengeTooltip } from "../ChallengeTooltip";

const MAX_MB_SIZE = 300;

export const TaskVideoInput = ({
  disabled,
  setVideoUrl,
  maxSeconds,
  taskId,
  showTooltip,
  onCloseTooltip,
}: TaskVideoInputProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box position="relative">
      <ChallengeTooltip
        isOpen={showTooltip}
        onClose={onCloseTooltip}
        placement="top-start"
        title="Clique no botão abaixo para escolher o melhor vídeo."
        containerProps={{
          position: "absolute",
          top: { xs: "-4rem", sm: "3rem" },
          left: { xs: 0, sm: "60%" },
        }}
      />

      <FileInput
        variant="video"
        component={
          <Container>
            {isLoading ? (
              <CircularProgress
                style={{ color: Theme.colors.feedback.info }}
                size="1.5rem"
              />
            ) : (
              <UploadIcon color={Theme.colors.feedback.info} />
            )}
            <Text
              align="center"
              color={Theme.colors.feedback.info}
              fontWeight={300}
              my="0.5rem"
            >
              Clique para enviar{"\n"}um vídeo
            </Text>
            <Text
              align="center"
              color={Theme.colors.text.secondary}
              fontWeight={300}
              fontSize="0.75rem"
            >
              MP4, MPEG-2 ou AVI (Max {MAX_MB_SIZE}MB)
            </Text>
          </Container>
        }
        disabled={disabled}
        getSignedUrlService={() => getUploadMediaChallengeVideoUrl(taskId)}
        onUploaded={setVideoUrl}
        setIsLoading={setIsLoading}
        maxMbSize={MAX_MB_SIZE}
        maxVideoDuration={maxSeconds}
      />
    </Box>
  );
};
