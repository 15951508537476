import { isBefore } from "date-fns";
import { createContext, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

type LocalStorageContext = {
  accessToken: string | null;
  updateAccessToken: (val: string | null) => void;

  isTokenExpired: boolean;
  updateTokenExpirationTimestamp: (val: string | null) => void;
};

const LocalStorageContext = createContext<LocalStorageContext>(
  {} as LocalStorageContext
);

type LocalStorageProviderProps = {
  children: React.ReactNode;
};

export const LocalStorageProvider = ({
  children,
}: LocalStorageProviderProps) => {
  const location = useLocation();

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("AccessToken")
  );
  const [tokenExpirationTimestamp, setTokenExpirationTimestamp] = useState(
    localStorage.getItem("TokenExpirationTimestamp")
  );

  const updateAccessToken = (val: string | null) => {
    if (val === null) {
      localStorage.removeItem("AccessToken");
    } else {
      localStorage.setItem("AccessToken", val);
    }

    setAccessToken(val);
  };

  const updateTokenExpirationTimestamp = (val: string | null) => {
    if (val === null) {
      localStorage.removeItem("TokenExpirationTimestamp");
    } else {
      localStorage.setItem("TokenExpirationTimestamp", val);
    }

    setTokenExpirationTimestamp(val);
  };

  const isTokenExpired = useMemo(() => {
    if (tokenExpirationTimestamp === null) {
      return true;
    }

    const expirationDate = new Date(Number(tokenExpirationTimestamp));

    return isBefore(expirationDate, new Date());
  }, [tokenExpirationTimestamp, location.pathname]);

  return (
    <LocalStorageContext.Provider
      value={{
        accessToken,
        updateAccessToken,

        isTokenExpired,
        updateTokenExpirationTimestamp,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = (): LocalStorageContext =>
  useContext(LocalStorageContext);
