import { useUser } from "@/context/UserContext";
import { isValidDate } from "@/helpers/date.helper";
import { useResaleCode } from "@/hooks/useResaleCode";
import { getEmailValidation } from "@/services/auth";
import { Gender } from "@/services/auth/types/auth.types";
import { realityInfo } from "@/utils/realityInfo";
import { fullNameRegex, passwordRegex, phoneNumberRegex } from "@/utils/regex";
import { isAfter } from "date-fns";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

export const useSignup = () => {
  const { signUp } = useUser();
  const { validateResaleCode, addUserResaleCode } = useResaleCode();

  const [isOpenEmailDialog, setIsOpenEmailDialog] = useState(true);
  const [isOpenAlreadyRegistered, setIsOpenAlreadyRegistered] = useState(false);
  const [isOpenAlreadyPdc, setIsOpenAlreadyPdc] = useState(false);
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState<Gender>();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [confirmWorker, setConfirmWorker] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [resaleCode, setResaleCode] = useState<string>();
  const [signupWithResaleCode, setSignupWithResaleCode] = useState(false);
  const [isVisibleResaleCodeTooltip, setIsVisibleResaleCodeTooltip] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [currentStep, setCurrentStep] = useState(1);

  const goBack = () => {
    window.history.back();
  };

  const goToSignIn = () => {
    window.open(`${window.location.origin}/sign-in`);
  };

  const onValidateEmail = async () => {
    if (!email) return;

    try {
      setIsLoadingButton(true);
      const res = await getEmailValidation(email);

      setIsOpenEmailDialog(false);
      if (res?.isCognitoUser && res.isRealityUser) {
        setIsOpenAlreadyRegistered(true);
      } else if (res?.isCognitoUser) {
        setIsOpenAlreadyPdc(true);
      } else {
        setIsOpenSignUp(true);
      }
    } catch (err) {
      console.error("Error validating email", err);
      toast("Erro validando email", { type: "error" });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const closeSignupDialog = () => {
    setIsOpenSignUp(false);
  };

  const toggleIsVisibleResaleCodeTooltip = () => {
    setIsVisibleResaleCodeTooltip((old) => !old);
  };

  const rawPhoneNumber = useMemo(
    () => phoneNumber.replace(/[^\d]/g, ""),
    [phoneNumber]
  );

  const phoneNumberError = useMemo(() => {
    if (rawPhoneNumber?.length === 0) return "";

    const isValid = phoneNumberRegex.test(rawPhoneNumber);

    if (!isValid) {
      return "Número de telefone inválido.";
    }

    return "";
  }, [rawPhoneNumber]);

  const nameError = useMemo(() => {
    if (!name) return "";

    const isValid = fullNameRegex.test(name);

    if (!isValid) {
      return "Nome incompleto.";
    }

    return "";
  }, [name]);

  const rawBirthdate = useMemo(() => {
    if (!birthDate) return "";

    const [day, month, year] = birthDate.split("/");

    return `${year}-${month}-${day}`;
  }, [birthDate]);

  const birthDateError = useMemo(() => {
    if (rawBirthdate?.length === 0) return "";

    if (!isValidDate(rawBirthdate) || isAfter(rawBirthdate, new Date())) {
      return "Data inválida.";
    }

    return "";
  }, [rawBirthdate]);

  const isValid = useMemo(() => {
    return (
      !!email &&
      !!name &&
      !nameError &&
      !!rawPhoneNumber &&
      !phoneNumberError &&
      !!rawBirthdate &&
      !birthDateError &&
      !!gender &&
      !!selectedState &&
      !!selectedCity &&
      !!password &&
      !!confirmPassword &&
      confirmWorker &&
      confirmTerms
    );
  }, [
    email,
    name,
    nameError,
    phoneNumberError,
    birthDateError,
    gender,
    selectedState,
    selectedCity,
    password,
    confirmPassword,
    confirmWorker,
    confirmTerms,
  ]);

  const buttonText = useMemo(() => {
    if (realityInfo.showResaleCode && currentStep === 1) {
      return "Avançar";
    }

    if (realityInfo.showResaleCode) {
      return "Finalizar inscrição com o código";
    }

    return "Inscreva-se";
  }, [realityInfo.showResaleCode, currentStep]);

  const successModalText = useMemo(() => {
    if (realityInfo.showResaleCode && !signupWithResaleCode) {
      return 'Conta criada com sucesso!\nNão se preocupe se você ainda não possui o código da revenda, poderá inseri-lo a qualquer momento na página inicial do portal, através do botão: "Insira o código".';
    }

    return "Conta criada com sucesso! Clique abaixo para fazer login.";
  }, [realityInfo.showResaleCode, signupWithResaleCode]);

  const onPressButton = async (withResaleCode = false) => {
    if (!isValid) {
      return toast("Preencha todos os campos corretamente", { type: "error" });
    }

    if (password !== confirmPassword) {
      toast("As senhas devem ser iguais", { type: "error" });
      return;
    }

    if (!password.match(passwordRegex)) {
      toast("A senha deve seguir as regras definidas abaixo.", {
        type: "error",
      });
      return;
    }

    if (realityInfo.showResaleCode) {
      if (currentStep === 1) {
        return setCurrentStep(2);
      }

      if (withResaleCode) {
        setSignupWithResaleCode(true);
        if (!resaleCode) {
          return toast(
            "Para seguir com o código, é necessário preencher o campo do código da revenda.",
            { type: "error" }
          );
        }

        const isValid = await validateResaleCode(resaleCode);

        if (!isValid) {
          return toast(
            "O código da revenda inserido está incorreto. Verifique e tente novamente.",
            { type: "error" }
          );
        }
      } else {
        setSignupWithResaleCode(false);
        return onSignUp();
      }
    }

    onSignUp(resaleCode);
  };

  const onSignUp = async (finalResaleCode?: string) => {
    try {
      setIsLoadingButton(true);

      await signUp({
        email,
        name,
        phoneNumber,
        birthDate: rawBirthdate,
        gender: gender!,
        state: selectedState,
        city: selectedCity,
        password,
        resaleCode: finalResaleCode,
      });
      if (finalResaleCode) {
        await addUserResaleCode(finalResaleCode, email);
      }
      setIsSignUpSuccess(true);
    } catch (err) {
      console.error("Error on signup", err);
      toast("Erro ao criar conta", { type: "error" });
      Sentry.captureException(err);
    } finally {
      setIsLoadingButton(false);
    }
  };

  return {
    isOpenEmailDialog,
    isOpenAlreadyRegistered,
    isOpenAlreadyPdc,
    isOpenSignUp,
    isSignUpSuccess,
    successModalText,
    goBack,
    goToSignIn,
    onValidateEmail,
    closeSignupDialog,
    isLoadingButton,
    currentStep,
    setCurrentStep,

    email,
    setEmail,
    name,
    setName,
    nameError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    gender,
    setGender,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    showPassword,
    toggleShowPassword,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,

    confirmWorker,
    setConfirmWorker,
    confirmTerms,
    setConfirmTerms,

    resaleCode,
    setResaleCode,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,

    buttonText,
    onPressButton,
  };
};
