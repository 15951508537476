import { realityId } from "@/utils/reality-id";
import api from "../api";
import { GetStagesResponse, StageWithChallenge } from "./types/stages.types";

export const getAllRealityStages = async (): Promise<StageWithChallenge[]> => {
  const res = await api.get<GetStagesResponse>(
    `/realities/${realityId}/stages/all?challengesWithPoints=true`
  );

  return res.data.data;
};
