import { memo } from "react";
import { CardButtonProps } from "./props.type";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChevronRight from "@/ui/icons/ChevronRight";
import Theme from "@/utils/theme";

const CardButton = ({ label, onClick, lockButton }: CardButtonProps) => {
  return (
    <Button
      variant="contained"
      disabled={lockButton}
      onClick={onClick}
      fullWidth={false}
      style={{ padding: "0.3rem 1rem", minWidth: "12rem", fontSize: "1rem" }}
    >
      {lockButton ? "Curso encerrado" : label}

      {!lockButton && (
        <>
          <Spacer width="0.5rem" />

          <ChevronRight color={Theme.colors.button.contained.primary.text} />
        </>
      )}
    </Button>
  );
};

export default memo(CardButton);
