import Theme from "@/utils/theme";
import { Button, ButtonProps, ThemeProvider, createTheme } from "@mui/material";
import { memo } from "react";

const ResaleCodeButton = (props: ButtonProps) => {
  const theme = createTheme({
    palette: {
      primary: { main: Theme.colors.feedback.negative },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            fontWeight: 400,
            fontSize: "0.87rem",
            borderRadius: "0.5rem",
          }}
          {...props}
        >
          Insira o código
        </Button>
      </ThemeProvider>
    </>
  );
};

export default memo(ResaleCodeButton);
