import { useAuthenticatedQuery } from "@/hooks/useAuthenticatedQuery";
import {
  signIn as signInService,
  signUp as signUpService,
} from "@/services/auth";
import { SignUpPayload } from "@/services/auth/types/auth.types";
import { queryClient } from "@/services/react-query";
import { getUserInfo } from "@/services/users";
import { UserInfo } from "@/services/users/types/user.types";
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./LocalStorageContext";

type UserContext = {
  userInfo?: UserInfo;
  isLoadingUser: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (payload: SignUpPayload) => Promise<void>;
  logOut: () => void;
  refetchUserInfo: () => void;
};

const UserContext = createContext<UserContext>({} as UserContext);

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const navigate = useNavigate();
  const { updateAccessToken, updateTokenExpirationTimestamp } =
    useLocalStorage();

  const { data: userInfo, isLoading: isLoadingUser } = useAuthenticatedQuery(
    ["user-info"],
    getUserInfo
  );

  //   TODO: Add subscribe
  const signIn = async (email: string, password: string) => {
    const authToken = await signInService(email, password);
    const AccessToken = authToken?.AuthenticationResult?.AccessToken;
    const ExpiresIn = authToken?.AuthenticationResult?.ExpiresIn ?? 0;

    if (!AccessToken) {
      throw new Error(
        `Access token not found on object authToken: ${JSON.stringify(
          authToken
        )}`
      );
    }

    updateAccessToken(AccessToken);

    const now = new Date();
    let expirationDate = now;
    expirationDate.setSeconds(now.getSeconds() + ExpiresIn);
    updateTokenExpirationTimestamp(expirationDate.getTime().toString());

    navigate("/dashboard");
  };

  const signUp = async ({
    phoneNumber,
    resaleCode,
    ...payload
  }: SignUpPayload) => {
    await signUpService({
      ...payload,
      phoneNumber: phoneNumber.replace(/[() -]/g, ""),
      resaleCode: resaleCode ?? undefined,
    });
  };

  const logOut = () => {
    updateAccessToken(null);
    updateTokenExpirationTimestamp(null);

    navigate("/sign-in");

    queryClient.cancelQueries();
  };

  const refetchUserInfo = () => {
    queryClient.invalidateQueries({
      queryKey: "user-info",
      exact: false,
    });
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        isLoadingUser,
        signIn,
        signUp,
        logOut,
        refetchUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContext => useContext(UserContext);
