import CardWithShadow from "@/components/CardWithShadow";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { Box, CircularProgress } from "@mui/material";
import Spacer from "@/components/Spacer";
import ShapedAccordion from "@/components/ShapedAccordion";
import AccordionSummary from "./components/AccordionSummary";
import AccordionDetailsList from "./components/AccordionDetailsList";
import { useChallenges } from "./useChallenges";
import { useStages } from "@/context/StagesContext";
import TotalChallenges from "@/ui/icons/TotalChallenges";
import SectionDescription from "../../components/SectionDescription";
import ChallengesInfoCard from "./components/ChallengesInfoCard";

function Challenges() {
  const {
    completedChallenges,
    totalChallenges,
    setExpandedIndexes,
    getIsOpenAccordion,

    challengesPerStage,
    updateChallengePerStage,
  } = useChallenges();
  const { stages, isLoading } = useStages();

  return (
    <div>
      <ChallengesInfoCard />

      <SectionDescription
        title="Complete os desafios"
        description="Mestre, aqui você deverá {{completar}} os desafios disponíveis para {{conquistar}} mais {{pontos}}. Clique em {{'Iniciar desafio'}} para começar. Fique atento: caso algum desafio {{seja reprovado}}, você poderá {{tentar novamente}} até a finalização desta etapa."
      />

      <CardWithShadow mt="1.5rem" py="1rem">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="1.5rem"
        >
          <Text color={Theme.colors.icon.default}>Lista de Desafios</Text>

          <Box
            display="flex"
            border={`1px solid ${Theme.colors.neutral.light}`}
            p="0.5rem"
            alignItems="center"
            borderRadius="0.5rem"
          >
            <TotalChallenges />

            <Text ml="0.5rem" color={Theme.colors.text.secondary}>
              Total Concluído:{" "}
            </Text>
            <Text color={Theme.colors.text.primary}>
              {completedChallenges}/{totalChallenges}
            </Text>
          </Box>
        </Box>

        <Spacer height="1rem" />
        <Box px={{ xs: "0px", sm: "2rem" }}>
          {isLoading && (
            <Box display="flex" justifyContent="center" p="1rem">
              <CircularProgress size="1rem" />
            </Box>
          )}
          <ShapedAccordion
            onChangeExpanded={setExpandedIndexes}
            items={
              stages.map((item, index) => ({
                summary: (
                  <AccordionSummary
                    stepNumber={index + 1}
                    title={item.subTitle}
                    totalComplete={item.completedChallenges}
                  />
                ),
                details: (
                  <AccordionDetailsList
                    stageId={item.id}
                    isOpen={getIsOpenAccordion(index)}
                    onChangeChallengesAmount={(val) =>
                      updateChallengePerStage(item.id, val)
                    }
                  />
                ),
                hidden: challengesPerStage[item.id] === 0,
              })) ?? []
            }
            fixedIndex={(stages?.length ?? 0) - 1}
          />
        </Box>
      </CardWithShadow>
    </div>
  );
}

export default Challenges;
