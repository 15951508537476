import { useNavigate } from "react-router-dom";

export const useDashboard = () => {
  const navigate = useNavigate();

  const handleNavigateToCourse = () => {
    navigate("/courses");
  };

  const handleNavigateToChallenges = () => {
    navigate("/challenges");
  };

  return {
    handleNavigateToCourse,
    handleNavigateToChallenges,
  };
};
