import CardWithShadow from "@/components/CardWithShadow";
import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";
import styled from "styled-components";
import FullscreenIcon from "/assets/icons/fullscreen.svg";
import { mobileBreakpoint } from "@/utils/mobile-breakpoint";

export const BackButton = (props: BoxProps) => (
  <CardWithShadow
    flexDirection="row"
    width="fit-content"
    alignItems="center"
    p="1rem"
    style={{ cursor: "pointer" }}
    alignSelf={{ xs: "center", sm: "start" }}
    {...props}
  />
);

export const OutlinedBox = (props: BoxProps) => (
  <Box
    display="flex"
    position="absolute"
    border={`1px solid ${Theme.colors.neutral.mid}`}
    p="0.5rem"
    borderRadius="0.5rem"
    height="1rem"
    {...props}
  />
);

export const QuizTextBox = (props: BoxProps) => (
  <OutlinedBox
    position="relative"
    width="100%"
    height="auto"
    mt="1rem"
    p="1rem 0rem"
    flexDirection="column"
    alignItems="center"
    {...props}
  />
);

export const MediaTutorialWrapper = (props: BoxProps) => (
  <Box
    margin="0 auto"
    maxWidth="max-content"
    position="relative"
    style={{ cursor: "pointer" }}
    {...props}
  />
);

export const TutorialMedia = styled.img`
  width: 37rem;
  height: 20.8rem;
  object-fit: cover;

  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.mid};

  @media (max-width: ${mobileBreakpoint}) {
    width: 100%;
    height: auto;
  }
`;

export const FullscreenButton = styled.img.attrs({
  src: FullscreenIcon,
})`
  z-index: 10;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;
