import axios from "axios";

const resaleApi = axios.create({
  baseURL: import.meta.env.VITE_RESALE_API_URL,
  timeout: 15000,
});

// TODO: verify if is exposing password
resaleApi.interceptors.request.use(
  async (config) => {
    config.auth = {
      username: import.meta.env.VITE_RESALE_API_USER,
      password: import.meta.env.VITE_RESALE_API_PASSWORD,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default resaleApi;
