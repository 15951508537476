import { getUserNotifications } from "@/services/notifications";
import {
  NotificationType,
  UserNotification,
} from "@/services/notifications/types/notification.types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useNotificationButton = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<UserNotification[]>();
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = !!anchorEl;

  const [showNotificationModalId, setShowNotificationModalId] = useState(-1);
  const toggleNotificationModal = (id: number) => {
    if (showNotificationModalId === -1) {
      return setShowNotificationModalId(id);
    }

    setShowNotificationModalId(-1);
  };

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickNotification = ({ id, type, challenge }: UserNotification) => {
    if (type === NotificationType.CHALLENGE_STARTED) {
      onClose();
      return navigate("/challenges");
    }

    if (
      (type === NotificationType.CHALLENGE_APPROVED ||
        type === NotificationType.CHALLENGE_REJECTED) &&
      challenge
    ) {
      const url =
        challenge.type === "QuizChallenge"
          ? `quiz/${challenge.id}`
          : `media/${challenge.id}`;

      onClose();
      return navigate(`/challenges/${url}`);
    }

    if (type === NotificationType.NEW_COURSE) {
      onClose();
      return navigate(`/courses`);
    }

    toggleNotificationModal(id);
  };

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      const res = await getUserNotifications();

      setNotifications(res);
    } catch (err) {
      console.error("Error getting notifications", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [isOpen]);

  return {
    anchorEl,
    isOpen,
    onClick,
    onClose,

    isLoading,
    notifications,
    showNotificationModalId,
    toggleNotificationModal,
    onClickNotification,
  };
};
