import Spacer from "@/components/Spacer";
import Text from "@/components/Text";
import AuthWrapper from "@/layout/AuthWrapper";
import { Box, CircularProgress } from "@mui/material";
import PasswordInput from "@/components/PasswordInput";
import Button from "@/components/Button";
import TextField from "@/components/TextField";
import { useSignin } from "./useSignIn";
import { useReality } from "@/context/RealityContext";
import Dialog from "@/components/Dialog";

export default function SignIn() {
  const {
    email,
    setEmail,
    emailError,
    password,
    setPassword,
    onClickForgotPassword,
    onClickSignIn,
    isLoading,
    onClickSignUp,

    showSubscriptionsEndedDialog,
    toggleSubscriptionsEndedDialog,
  } = useSignin();
  const { isSubscriptionOpen } = useReality();

  return (
    <AuthWrapper
      pageTitle="FAÇA O LOGIN"
      stepsSubtitle="Aumente seus pontos"
      stepsDescription="Para aumentar sua pontuação com desafios e cursos, faça o login com os mesmos dados cadastrados no Parceiro da Construção. Se precisar, clique em “Esqueci minha senha” para recuperar seus dados."
    >
      <Box display="flex" flexDirection="column">
        <Text fontSize="1rem" mt="0.5rem" mb="1rem" alignSelf="center">
          Entre aqui
        </Text>

        <TextField
          label="E-mail"
          value={email}
          setValue={setEmail}
          error={!!emailError}
          helperText={emailError}
        />

        <Spacer height="1rem" />
        <PasswordInput
          label="Senha"
          value={password}
          setValue={setPassword}
          size="small"
        />

        <Spacer height="1rem" />
        <Button
          variant="text"
          onClick={onClickForgotPassword}
          sx={{ textDecorationLine: "underline" }}
        >
          Esqueci minha senha
        </Button>

        <Spacer height="2rem" />
        <Button size="large" onClick={onClickSignIn}>
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            "Entrar"
          )}
        </Button>

        <Spacer height="1rem" />
        <Button
          size="large"
          color="secondary"
          onClick={onClickSignUp}
          disabled={!isSubscriptionOpen}
        >
          Quero me cadastrar
        </Button>
      </Box>

      <Dialog
        isOpen={showSubscriptionsEndedDialog}
        text="Período de inscrições foi encerrado."
        onConfirm={toggleSubscriptionsEndedDialog}
      />
    </AuthWrapper>
  );
}
