import { memo } from "react";
import { Box, TextField as MUITextField } from "@mui/material";
import Theme from "@/utils/theme";
import { CustomTextFieldProps } from "./props.type";

const TextField = ({
  ContainerProps,
  setValue,
  defaultToEmpty = true,
  onlyNumbers,
  blockChars,
  ...props
}: CustomTextFieldProps) => {
  return (
    <Box {...ContainerProps}>
      <MUITextField
        fullWidth
        size="small"
        InputLabelProps={{ style: { color: Theme.colors.text.secondary } }}
        {...props}
        onChange={(e) => {
          const value = e.target.value;
          if (onlyNumbers && isNaN(Number(value))) return;
          if (blockChars?.includes(value)) return;

          let finalValue = value;
          blockChars?.forEach((char) => {
            finalValue = finalValue.trim().replace(char, "");
          });

          setValue?.(finalValue);
          props.onChange?.(e);
        }}
        value={defaultToEmpty ? props.value ?? "" : props.value}
        sx={{
          "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: "-1.1rem",
          },
          ...props.sx,
        }}
      >
        {props.children}
      </MUITextField>
    </Box>
  );
};

export default memo(TextField);
