import { memo } from "react";
import { Container, Logo } from "./styles";
import Divider from "../../../../components/Divider";
import SidebarButton from "./components/SidebarButton";
import { SidebarButtonProps } from "./components/SidebarButton/props.type";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ buttons }: { buttons: SidebarButtonProps[] }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Logo alt="Logo" onClick={() => navigate("/dashboard")} />

      <Divider margin="1rem 0rem" />

      {buttons.map((button) => (
        <SidebarButton key={"sidebar_button_" + button.path} {...button} />
      ))}
    </Container>
  );
};

export default memo(Sidebar);
