import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { ThemeProvider, createTheme } from "@mui/material";

const EyeIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  const theme = createTheme({
    palette: { primary: { main: color } },
  });

  return (
    <ThemeProvider theme={theme}>
      <VisibilityOutlinedIcon
        style={{ width: size, height: size }}
        color="primary"
      />
    </ThemeProvider>
  );
};

export default memo(EyeIcon);
