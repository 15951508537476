import { memo } from "react";
import { Box } from "@mui/material";
import { AccordionSummaryProps } from "./props.type";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import Spacer from "@/components/Spacer";
import { realityInfo } from "@/utils/realityInfo";

const AccordionSummary = ({
  stepNumber,
  title,
  totalComplete,
}: AccordionSummaryProps) => {
  return (
    <Box display="flex" flex={1}>
      <Text
        color={
          realityInfo.isDarkAccordion
            ? Theme.colors.neutral.lightest
            : Theme.colors.neutral.dark
        }
      >
        {stepNumber}ª Etapa
      </Text>
      <Text color={Theme.colors.neutral.mid}> | {title}</Text>

      <Spacer grow />

      <Text
        color={
          realityInfo.isDarkAccordion
            ? Theme.colors.neutral.lightest
            : Theme.colors.feedback.info
        }
      >
        {totalComplete}{" "}
      </Text>
      <Text color={Theme.colors.neutral.mid}>
        Concluído{totalComplete > 1 ? "s" : ""}
      </Text>
    </Box>
  );
};

export default memo(AccordionSummary);
