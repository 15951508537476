import { memo } from "react";
import {
  Container,
  Logo,
  MobileLogoWrapper,
  RightContentWrapper,
  UserImage,
  UserInfoWrapper,
} from "./styles";
import Text from "@/components/Text";
import Spacer from "@/components/Spacer";
import { useToolbar } from "./hooks/useToolbar";
import IconButton from "@/components/IconButton";
import { MobileOnly } from "@/shared/responsive-layouts";
import { Box, Drawer } from "@mui/material";
import { SidebarButtonProps } from "../Sidebar/components/SidebarButton/props.type";
import SidebarButton from "../Sidebar/components/SidebarButton";
import Button from "@/components/Button";
import { useUser } from "@/context/UserContext";
import avatar from "/assets/avatar.png";
import NotificationButton from "./components/NotificationButton";
import Theme from "@/utils/theme";

const Toolbar = ({ buttons }: { buttons: SidebarButtonProps[] }) => {
  const user = useUser();

  const {
    currentPageTitle,
    onClickLogo,
    onClickUserInfo,
    onClickLogOut,
    isOpenDrawer,
    toggleDrawer,
  } = useToolbar();

  return (
    <Container>
      <MobileLogoWrapper>
        <Logo alt="Logo" onClick={onClickLogo} />
        <Spacer grow />
      </MobileLogoWrapper>

      <Text color={Theme.colors.toolbar.text}>{currentPageTitle}</Text>

      <RightContentWrapper>
        <MobileOnly>
          <IconButton
            icon="menu"
            color={Theme.colors.toolbar.icon}
            onClick={toggleDrawer}
          />
          <Spacer width="1rem" />
        </MobileOnly>
        <NotificationButton />

        <Box display={{ xs: "none", sm: "flex" }}>
          <UserInfoWrapper onClick={onClickUserInfo}>
            <Spacer width="1rem" />
            <UserImage src={user.userInfo?.imageUrl || avatar} />

            <Spacer width="0.5rem" />
            <Text color={Theme.colors.toolbar.text}>{user.userInfo?.name}</Text>
          </UserInfoWrapper>

          <Spacer width="1rem" />
          <IconButton icon="logout" onClick={onClickLogOut} />
        </Box>
      </RightContentWrapper>
      <Drawer
        open={isOpenDrawer}
        onClose={toggleDrawer}
        PaperProps={{
          style: { backgroundColor: Theme.colors.background.secondary },
        }}
      >
        <Box pt="3rem" minWidth="60vw">
          {buttons.map((button) => (
            <SidebarButton
              key={"toolbar_sidebar_button_" + button.path}
              {...button}
              onClick={() => {
                button.onClick?.();
                toggleDrawer();
              }}
            />
          ))}
        </Box>

        <Spacer grow />

        <Button
          color="error"
          variant="text"
          style={{ marginBottom: "1.5rem" }}
          onClick={onClickLogOut}
        >
          Sair
        </Button>
      </Drawer>
    </Container>
  );
};

export default memo(Toolbar);
