import { memo } from "react";
import { Box } from "@mui/material";
import { DateInputProps } from "./props.type";
import MaskedInput from "../MaskedInput";

const DateInput = ({ ContainerProps, ...props }: DateInputProps) => {
  return (
    <Box {...ContainerProps}>
      <MaskedInput mask="99/99/9999" blockChars={[" "]} {...props} />
    </Box>
  );
};

export default memo(DateInput);
