import Theme from "@/utils/theme";
import { MenuItemProps, Box, BoxProps, MenuItem } from "@mui/material";

export const QuestionNumberWrapper = (props: BoxProps) => (
  <Box
    display="flex"
    bgcolor={Theme.colors.brand.secondary}
    minHeight="1.5rem"
    height="fit-content"
    width="auto"
    style={{ aspectRatio: "1/1" }}
    mr="1rem"
    p="0.5rem"
    alignItems="center"
    justifyContent="center"
    borderRadius="10rem"
    {...props}
  />
);

export const QuestionWrapper = (props: BoxProps) => (
  <Box
    display="flex"
    flexDirection="column"
    border={`1px solid ${Theme.colors.neutral.mid}`}
    borderRadius="0.5rem"
    mt="1rem"
    {...props}
  />
);

export const AlternativeBullet = ({
  isSelected,
  ...props
}: { isSelected: boolean } & BoxProps) => (
  <Box
    display="flex"
    width="auto"
    style={{ aspectRatio: "1/1" }}
    border={`1px solid ${Theme.colors.brand.secondary}`}
    borderRadius="10rem"
    p="0.3rem"
    mr="1rem"
    {...props}
  >
    <Box
      bgcolor={isSelected ? Theme.colors.brand.secondary : "transparent"}
      height="1.5rem"
      width="1.5rem"
      borderRadius="10rem"
      style={{ transition: "background-color 100ms linear" }}
    />
  </Box>
);

export const AlternativeWrapper = ({
  isSelected,
  ...props
}: { isSelected: boolean } & MenuItemProps) => (
  <MenuItem
    style={{
      border: `1px solid ${
        isSelected ? Theme.colors.brand.secondary : Theme.colors.neutral.mid
      }`,
      borderRadius: "0.2rem",
      marginTop: "0.7rem",
    }}
    {...props}
  />
);
