import { useUser } from "@/context/UserContext";
import { useResaleCode } from "@/hooks/useResaleCode";
import { getUserPoints } from "@/services/points";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const useUserInfoHeader = () => {
  const { userInfo } = useUser();
  const [isVisibleSaleCode, setIsVisibleSaleCode] = useState(false);
  const { getStoreInfo } = useResaleCode();
  const [storeName, setStoreName] = useState("");
  const [isVisibleResaleCodeTooltip, setIsVisibleResaleCodeTooltip] =
    useState(false);

  const { data: points } = useQuery("user-points", () => getUserPoints());

  const toggleIsVisibleSaleCode = () => {
    setIsVisibleSaleCode((old) => !old);
  };

  const toggleIsVisibleResaleCodeTooltip = () => {
    setIsVisibleResaleCodeTooltip((old) => !old);
  };

  const addStoreName = async () => {
    if (userInfo?.resaleCode) {
      const res = await getStoreInfo(userInfo?.resaleCode);
      setStoreName(res.name);
    }
  };

  useEffect(() => {
    addStoreName();
  }, [userInfo]);

  return {
    userInfo,
    isVisibleSaleCode,
    toggleIsVisibleSaleCode,
    points,
    storeName,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,
  };
};
