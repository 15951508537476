import { useMemo } from "react";
import { IconButtonProps } from "../props.type";
import LogoutIcon from "/assets/icons/logout.svg";
import DeleteIcon from "@/ui/icons/DeleteIcon";
import MenuIcon from "@/ui/icons/MenuIcon";
import NotificationIcon from "@/ui/icons/NotificationIcon";
import OpenIcon from "@/ui/icons/OpenIcon";
import SettingsIcon from "@/ui/icons/SettingsIcon";
import ArrowBackIcon from "@/ui/icons/ArrowBackIcon";
import ChatOutlinedIcon from "@/ui/icons/ChatOutlinedIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Theme from "@/utils/theme";

export const useIconButton = ({
  icon,
  color,
  disabled,
}: Partial<IconButtonProps>) => {
  const finalColor = useMemo(() => {
    if (disabled) return Theme.colors.neutral.mid_dark;

    return color;
  }, [color, disabled]);

  const Icon = useMemo(() => {
    switch (icon) {
      case "notification":
        return <NotificationIcon color={finalColor} />;
      case "logout":
        return <img src={LogoutIcon} />;
      case "menu":
        return <MenuIcon color={finalColor} />;
      case "settings":
        return <SettingsIcon size={16} color={finalColor} />;
      case "open":
        return <OpenIcon color={finalColor} />;
      case "delete":
        return <DeleteIcon color={finalColor} />;
      case "back":
        return <ArrowBackIcon color={finalColor} />;
      case "chat":
        return <ChatOutlinedIcon color={finalColor} />;
      case "info":
        return <InfoOutlinedIcon sx={{ color: finalColor }} />;
    }
  }, [icon, finalColor]);

  return { Icon };
};
