import Spacer from "@/components/Spacer";
import Text from "@/components/Text";
import AuthWrapper from "@/layout/AuthWrapper";
import { Box, CircularProgress } from "@mui/material";
import Button from "@/components/Button";
import TextField from "@/components/TextField";
import { useForgotPassword } from "./useForgotPassword";
import Dialog from "@/components/Dialog";
import PdcFooter from "@/components/PdcFooter";
import CircleCheck from "@/ui/icons/CircleCheck";

export default function ForgotPassword() {
  const {
    email,
    setEmail,
    emailError,

    showDialog,
    onConfirmDialog,

    onCancel,

    isLoading,
    onSubmit,
  } = useForgotPassword();

  return (
    <AuthWrapper pageTitle="FAÇA O LOGIN" titleMargin="3rem">
      <Box display="flex" flexDirection="column">
        <Text fontSize="1rem" mt="1rem" alignSelf="center">
          Recuperação de senha
        </Text>

        <Spacer height="1.5rem" />
        <TextField
          label="E-mail"
          value={email}
          setValue={setEmail}
          error={!!emailError}
          helperText={emailError}
        />

        <Spacer height="1.5rem" />
        <Button size="large" onClick={onSubmit} disabled={!!emailError}>
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            "Enviar e-mail de recuperação"
          )}
        </Button>
        <Spacer height="1.5rem" />
        <Button
          size="large"
          onClick={onCancel}
          variant="text"
          style={{textDecoration: 'underline'}}
        >
          Cancelar
        </Button>
      </Box>

      <Dialog
        isOpen={showDialog}
        icon={<CircleCheck />}
        text="Caso o e-mail informado seja uma conta válida, um e-mail para redefinição de senha foi enviado."
        confirmButtonText="Entendi"
        onConfirm={onConfirmDialog}
        footer={
          <PdcFooter text={`E-mail de recuperação\nenviado através do:`} />
        }
      />
    </AuthWrapper>
  );
}
