import { memo } from "react";
import { ChevronButtonProps } from "./props.type";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChevronRight from "@/ui/icons/ChevronRight";
import Theme from "@/utils/theme";

const ChevronButton = ({ label, ...props }: ChevronButtonProps) => {
  return (
    <Button
      variant="contained"
      fullWidth={false}
      style={{ padding: "0.3rem 1rem", minWidth: "12rem", fontSize: "1rem" }}
      {...props}
    >
      {label}
      <Spacer width="0.5rem" />

      <ChevronRight color={Theme.colors.button.contained.primary.text} />
    </Button>
  );
};

export default memo(ChevronButton);
