import { memo } from "react";
import { Container, CurrentPointsContainer } from "./styles";
import { PointsSliderProps } from "./props.type";
import Text from "@/components/Text";
import { Box } from "@mui/material";
import Theme from "@/utils/theme";

const PointsSlider = ({
  currentPoints,
  totalPoints,
  usePercentage,
}: PointsSliderProps) => {
  return (
    <Container>
      <CurrentPointsContainer widthPercentage={currentPoints / totalPoints}>
        <Text
          color={Theme.colors.button.contained.primary.text}
          fontSize="0.75rem"
          fontWeight={600}
        >
          {usePercentage
            ? `${(currentPoints / totalPoints) * 100}%`
            : `${currentPoints} pts`}
        </Text>
      </CurrentPointsContainer>

      <Box position="absolute" right={0} zIndex={1}>
        <Text
          color={Theme.colors.neutral.mid_dark}
          fontSize="0.75rem"
          fontWeight={600}
          mr="1rem"
        >
          {usePercentage ? "100" : totalPoints}
          {usePercentage ? "%" : " pts"}
        </Text>
      </Box>
    </Container>
  );
};

export default memo(PointsSlider);
