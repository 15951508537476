import Theme from "@/utils/theme";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const ColoredTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Theme.colors.brand.primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Theme.colors.brand.primary,
  },
}));
