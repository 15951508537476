import { memo } from "react";
import { Container, Icon } from "./styles";
import { SidebarButtonProps } from "./props.type";
import { useSidebarButton } from "../../hooks/useSidebarButton";
import Text from "@/components/Text";
import Theme from "@/utils/theme";

const SidebarButton = ({
  icon,
  label,
  path,
  onClick,
  overwriteClick,
}: SidebarButtonProps) => {
  const { onClickButton, isSelected } = useSidebarButton(path);

  return (
    <Container
      onClick={() => {
        if (!overwriteClick) {
          onClickButton();
        }
        onClick?.();
      }}
      isSelected={isSelected}
    >
      {/* @ts-ignore */}
      <Icon src={icon} isSelected={isSelected} />

      <Text
        color={
          isSelected
            ? Theme.colors.sidebar.text.active
            : Theme.colors.sidebar.text.default
        }
      >
        {label}
      </Text>
    </Container>
  );
};

export default memo(SidebarButton);
