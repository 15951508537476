import { createContext, useContext, useMemo } from "react";
import { ChallengeStage } from "@/services/challenges/types/challenges.types";
import { getRealityStages } from "@/services/challenges";
import { useUser } from "./UserContext";
import { useAuthenticatedQuery } from "@/hooks/useAuthenticatedQuery";
import { isBefore } from "date-fns";

type StagesContext = {
  stages: ChallengeStage[];
  currentStage?: ChallengeStage;
  currentStageNumber: number;
  isLoading: boolean;
  isValidStage: boolean;
  isStageClosed: boolean;
  isUserOutOfReality: boolean;
};

const StagesContext = createContext<StagesContext>({} as StagesContext);

type StagesProviderProps = {
  children: React.ReactNode;
};

export const StagesProvider = ({ children }: StagesProviderProps) => {
  const { userInfo } = useUser();
  const { data: stages, isLoading } = useAuthenticatedQuery(
    ["reality-stages", userInfo?.cognitoUserId],
    () => getRealityStages(),
    { retry: true }
  );

  const userStages = useMemo(() => {
    if (!stages) {
      return;
    }

    const sortedStages = stages.sort(
      (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
    );

    return sortedStages;
  }, [stages]);

  const currentStage = useMemo(() => {
    if (!userStages) {
      return;
    }

    return userStages[userStages.length - 1];
  }, [userStages]);

  const currentStageNumber = useMemo(() => {
    return userStages?.length ?? -1;
  }, [userStages]);

  const isValidStage = useMemo(() => {
    if (!currentStage) return true;

    return !isBefore(currentStage.endDate, new Date());
  }, [currentStage?.endDate]);

  const isStageClosed = useMemo(() => {
    return !!currentStage?.isClosed;
  }, [currentStage?.isClosed]);

  const isUserOutOfReality = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return isStageClosed && !isValidStage && !currentStage?.isFinalStage;
  }, [isStageClosed, isValidStage]);

  return (
    <StagesContext.Provider
      value={{
        stages: stages ?? [],
        currentStage,
        currentStageNumber,
        isValidStage,
        isStageClosed,
        isLoading,
        isUserOutOfReality,
      }}
    >
      {children}
    </StagesContext.Provider>
  );
};

export const useStages = (): StagesContext => useContext(StagesContext);
