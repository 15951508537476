import { memo } from "react";
import { PhoneInputProps } from "./props.type";
import { Box } from "@mui/material";
import MaskedInput from "../MaskedInput";

const PhoneInput = ({ ContainerProps, ...props }: PhoneInputProps) => {
  return (
    <Box {...ContainerProps}>
      <MaskedInput mask="(99) 99999-9999" blockChars={[" "]} {...props} />
    </Box>
  );
};

export default memo(PhoneInput);
