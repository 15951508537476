import { format, isValid, differenceInYears, addMinutes } from "date-fns";

export const getDateWithoutTimezone = (dateString?: string | Date) => {
  if (!dateString) {
    console.error("Error in getDateWithoutTimezone: Date string is empty");
    return new Date();
  }

  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset();

  const formatedDate = format(
    addMinutes(date, timezoneOffset),
    `yyyy-MM-dd'T'HH:mm:ss`
  );
  return new Date(formatedDate);
};

export const isValidDate = (date: string) => {
  return isValid(new Date(date));
};

export const getFormattedDate = (
  dateString?: string,
  ignoreTimezone?: boolean
) => {
  if (!dateString) return "";

  if (ignoreTimezone) {
    return format(getDateWithoutTimezone(dateString), "dd/MM/yyyy");
  }

  return format(dateString, "dd/MM/yyyy");
};

export const getAgeFromBirthDate = (birthDate: string | Date) => {
  const date = new Date(birthDate);
  return differenceInYears(new Date(), date);
};
