import { useUser } from "@/context/UserContext";
import { useResaleCode } from "@/hooks/useResaleCode";
import { updateUserInfo } from "@/services/users";
import { useEffect, useMemo, useState } from "react";

export const useResaleCodeModal = (onClose: () => void) => {
  const { userInfo, refetchUserInfo } = useUser();
  const { validateResaleCode, addUserResaleCode } = useResaleCode();
  const [resaleCode, setResaleCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const toggleSuccessDialog = () =>
    setIsOpenSuccessDialog(!isOpenSuccessDialog);

  const error = useMemo(() => {
    if (!resaleCode || isLoading) {
      return "";
    }

    if (resaleCode.length < 6) {
      return "O código da revenda deve conter uma letra e 5 números.";
    }

    if (!isValidCode) {
      return "Código digitado inválido ou inexistente.";
    }

    return "";
  }, [resaleCode, isValidCode, isLoading]);

  const onClickSubmit = async () => {
    try {
      setIsLoading(true);
      if (!userInfo?.email) return;

      await addUserResaleCode(resaleCode, userInfo.email);
      await updateUserInfo({ resaleCode });
      refetchUserInfo();
      showSuccessDialog();
    } catch (err) {
      console.error("Erro ao verificar código de revenda", err);
    } finally {
      setIsLoading(false);
    }
  };

  const validateCode = async () => {
    try {
      setIsLoading(true);

      if (resaleCode.length === 6) {
        const res = await validateResaleCode(resaleCode);
        setIsValidCode(res);
      } else {
        setIsValidCode(false);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateCode();
  }, [resaleCode.length]);

  const closeModalAndClean = () => {
    setResaleCode("");
    setIsValidCode(false);
    onClose();
  };

  const showSuccessDialog = () => {
    setIsOpenSuccessDialog(true);
    closeModalAndClean();
  };

  return {
    resaleCode,
    setResaleCode,
    isLoading,
    error,
    onClickSubmit,
    closeModalAndClean,

    isOpenSuccessDialog,
    toggleSuccessDialog,
  };
};
