import { realityId } from "@/utils/reality-id";
import api from "../api";
import {
  ChallengeStage,
  ChallengeStageDetails,
  CompletedChallenges,
  CompletedChallengesResponse,
} from "./types/challenges.types";

export const getRealityStages = async (): Promise<ChallengeStage[]> => {
  const res = await api.get(`/realities/${realityId}/stages`);
  return res.data.data;
};

export const getStageChallenges = async (
  stageId: string | number
): Promise<ChallengeStageDetails[]> => {
  const res = await api.get(
    `/realities/${realityId}/stages/${stageId}/challenges`
  );
  return res.data;
};

export const getCompletedChallenges = async (): Promise<
  CompletedChallenges | undefined
> => {
  const res = await api.get<CompletedChallengesResponse>(
    `/realities/${realityId}/completed-challenges`
  );

  return res.data.completedChallenges;
};
