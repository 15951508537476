import { ChallengeStageDetails } from "@/services/challenges/types/challenges.types";

export type UserNotification = {
  id: number;
  createdAt: string;
  title: string;
  type?: NotificationType;
  challenge?: ChallengeStageDetails;
  text: string;
  seen: boolean;
};

export enum NotificationType {
  CHALLENGE_STARTED = "CHALLENGE_STARTED",
  CHALLENGE_REJECTED = "CHALLENGE_REJECTED",
  CHALLENGE_APPROVED = "CHALLENGE_APPROVED",
  ADVANCED_STAGE = "ADVANCED_STAGE",

  NEW_COURSE = "NEW_COURSE",
}
