import { memo } from "react";
import {
  Container,
  FooterContentWrapper,
  HeaderFloatingButtonWrapper,
  HeaderFooter,
  HeaderWrapper,
  ImageWrapper,
  RotatedDivider,
  UserImage,
} from "./styles";
import Shape from "../Shape";
import { Box } from "@mui/material";
import Spacer from "../Spacer";
import IconButton from "../IconButton";
import Text from "../Text";
import Theme from "@/utils/theme";
import { useNavigate } from "react-router-dom";
import PointsSlider from "@/components/PointsSlider";
import avatar from "/assets/avatar.png";
import { useGetCompletedChallenges } from "@/hooks/useGetCompletedChallenges";
import { useStages } from "@/context/StagesContext";
import CircleCheck from "@/ui/icons/CircleCheck";
import InfoIcon from "@/ui/icons/InfoIcon";
import ResaleCodeModal from "@/components/UserInfoHeader/components/ResaleCodeModal";
import { realityInfo } from "@/utils/realityInfo";
import ResaleCodeButton from "./components/ResaleCodeButton";
import { useUserInfoHeader } from "./useUserInfoHeader";
import { getAgeFromBirthDate } from "@/helpers/date.helper";
import { Tooltip } from "../Tooltip";
import { useIsMobile } from "@/hooks/useIsMobile";

const UserInfoHeader = () => {
  const navigate = useNavigate();
  const { isValidStage } = useStages();

  const { isCompletedChallenges } = useGetCompletedChallenges();
  const {
    userInfo,
    isVisibleSaleCode,
    toggleIsVisibleSaleCode,
    points,
    storeName,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,
  } = useUserInfoHeader();
  const { isMobile } = useIsMobile();

  return (
    <Container>
      <HeaderWrapper hasBottomBorderRadius={!realityInfo.showResaleCode}>
        <Shape
          leftNode={
            <ImageWrapper>
              <UserImage src={userInfo?.imageUrl || avatar} />
            </ImageWrapper>
          }
        >
          <Spacer width="1rem" />
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            justifyContent="center"
            flex={1}
          >
            <Text fontSize="1.5rem">Olá, {userInfo?.name}!</Text>
            {userInfo?.birthDate && userInfo?.city && (
              <Text color={Theme.colors.text.secondary}>
                {getAgeFromBirthDate(userInfo.birthDate)} anos | {userInfo.city}
              </Text>
            )}

            <HeaderFloatingButtonWrapper>
              <IconButton
                icon="settings"
                onClick={() => navigate("/profile")}
              />
            </HeaderFloatingButtonWrapper>
          </Box>
        </Shape>

        <Box display={{ xs: "none", sm: "flex" }}>
          <RotatedDivider margin="0rem 1rem" vertical />
        </Box>
        <Box display={{ xs: "flex", sm: "none" }}>
          <Spacer height="0.5rem" />
        </Box>

        <Shape>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            justifyContent="center"
          >
            <Box display="flex">
              <Text color={Theme.colors.text.secondary}>Meus Pontos</Text>
            </Box>

            <Spacer height="1rem" />

            <PointsSlider
              currentPoints={points?.earnedPoints || 0}
              totalPoints={points?.maximumPoints || 0}
            />

            <Spacer height="1rem" />
            <Box display={isValidStage ? "flex" : "none"} alignItems="center">
              {isCompletedChallenges ? (
                <CircleCheck size={16} />
              ) : (
                <InfoIcon size={16} />
              )}

              <Text
                color={
                  isCompletedChallenges
                    ? Theme.colors.feedback.positive
                    : Theme.colors.feedback.info
                }
                fontSize="0.75rem"
                fontWeight={300}
                ml="0.2rem"
              >
                {isCompletedChallenges
                  ? "Parabéns! Você está em dia."
                  : "Finalize os desafios e cursos para conquistar mais pontos"}
              </Text>

              <Spacer grow />
            </Box>
          </Box>
        </Shape>
      </HeaderWrapper>

      {realityInfo.showResaleCode && (
        <HeaderFooter>
          <FooterContentWrapper hasCode={!!userInfo?.resaleCode}>
            {!!userInfo?.resaleCode && <CircleCheck size={24} />}

            <Text fontSize="0.85rem" fontWeight={400} mx="0.4rem">
              Código da Revenda:
            </Text>

            {userInfo?.resaleCode ? (
              <Text
                color={Theme.colors.feedback.positive}
                fontSize="0.85rem"
                fontWeight={500}
              >
                {userInfo.resaleCode}
              </Text>
            ) : (
              <ResaleCodeButton onClick={toggleIsVisibleSaleCode} />
            )}

            <Tooltip
              isOpen={isVisibleResaleCodeTooltip}
              onClose={toggleIsVisibleResaleCodeTooltip}
              placement={isMobile ? "bottom-end" : "bottom-start"}
              title="Este campo não é obrigatório. O Código da Revenda relaciona o seu perfil com uma Loja de Materiais para Construção. Entre em contato com a revenda Quartzolit mais próxima e solicite este código gratuito."
            >
              <Box
                display="flex"
                style={{ cursor: "pointer" }}
                p="0.25rem"
                onClick={toggleIsVisibleResaleCodeTooltip}
              >
                <InfoIcon size={20} />
              </Box>
            </Tooltip>

            {!!userInfo?.resaleCode && (
              <Box display={{ xs: "none", sm: "flex" }}>
                <Text color={Theme.colors.neutral.light}> | </Text>

                <Text>Você Está Representando a Revenda: </Text>
                <Text color={Theme.colors.feedback.positive}>{storeName}</Text>
              </Box>
            )}
          </FooterContentWrapper>
        </HeaderFooter>
      )}

      <ResaleCodeModal
        isOpen={isVisibleSaleCode}
        onClose={toggleIsVisibleSaleCode}
      />
    </Container>
  );
};

export default memo(UserInfoHeader);
