import { memo } from "react";
import Select from "../Select";
import { MenuItem } from "@mui/material";
import { useCitySelect } from "./useCitySelect";
import { CitySelectProps } from "./props.type";

const CitySelect = (props: CitySelectProps) => {
  const { cities, isLoading } = useCitySelect(props);

  return (
    <Select
      label="Cidade"
      {...props}
      disabled={props.disabled || cities.length === 0 || isLoading}
    >
      {cities.map((city) => (
        <MenuItem key={`city-select-${city.nome}`} value={city.nome}>
          {city.nome}
        </MenuItem>
      ))}
    </Select>
  );
};

export default memo(CitySelect);
