import { memo } from "react";
import { EmailDialogProps } from "./props.type";
import Dialog from "@/components/Dialog";
import PdcFooter from "@/components/PdcFooter";
import TextField from "@/components/TextField";

const EmailDialog = (props: EmailDialogProps) => {
  return (
    <Dialog
      {...props}
      maxWidth="33rem"
      text={`Atenção, mestre!\nPara iniciar sua inscrição, precisamos verificar seu e-mail. Insira abaixo um endereço de e-mail válido. [b]Se você já possui cadastro no Parceiro da Construção, insira o mesmo e-mail utilizado.[/b]`}
      content={
        <TextField
          ContainerProps={{ style: { width: "100%" }, mb: "1.5rem" }}
          blockChars={[" "]}
          value={props.email}
          setValue={props.setEmail}
          placeholder="E-mail"
          size="small"
          fullWidth
        />
      }
      confirmButtonText="Continuar Inscrição"
      closeButtonText="Sair"
      footer={<PdcFooter />}
    />
  );
};

export default memo(EmailDialog);
