import { Gender } from "@/services/auth/types/auth.types";

export const getGenderText = (gender: Gender) => {
  switch (gender) {
    case Gender.MALE:
      return "Masculino";
    case Gender.FEMALE:
      return "Feminino";
    default:
      return "Outros";
  }
};
