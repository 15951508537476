import styled from "styled-components";

export const EditImageButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 10%;
  right: 5%;

  padding: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;
