import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";
import Theme from "@/utils/theme";

const CircleCheck = ({
  color = Theme.colors.feedback.positive,
  size = defaultIconProps.size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 29.8332C8.63622 29.8332 2.66669 23.8636 2.66669 16.4998C2.66669 9.13604 8.63622 3.1665 16 3.1665C23.3638 3.1665 29.3334 9.13604 29.3334 16.4998C29.3253 23.8603 23.3605 29.8251 16 29.8332ZM15.9787 27.1665H16C21.889 27.1606 26.659 22.3835 26.656 16.4945C26.6531 10.6056 21.8783 5.83318 15.9894 5.83318C10.1004 5.83318 5.32563 10.6056 5.32269 16.4945C5.31974 22.3835 10.0897 27.1606 15.9787 27.1665ZM13.3334 23.1665L8.00002 17.8332L9.88002 15.9532L13.3334 19.3932L22.12 10.6065L24 12.4998L13.3334 23.1665Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(CircleCheck);
