import { memo } from "react";
import { DividerProps } from "./props.type";
import { Horizontal, Vertical } from "./styles";

const Divider = (props: DividerProps) => {
  if (props.vertical) {
    return <Vertical {...props} />;
  }

  return <Horizontal {...props} />;
};

export default memo(Divider);
