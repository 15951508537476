import Dialog from "@/components/Dialog";
import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { noAuthRequired } from "./ProtectRoutesContext";
import { useUser } from "./UserContext";
import { useStages } from "./StagesContext";
import PartyIcon from "/assets/icons/party.svg";

type ProfileInfoContext = {};

const ProfileInfoContext = createContext<ProfileInfoContext>(
  {} as ProfileInfoContext
);

type ProfileInfoProviderProps = {
  children: React.ReactNode;
};

export const ProfileInfoProvider = ({ children }: ProfileInfoProviderProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const {
    currentStage,
    currentStageNumber,
    isLoading: isLoadingStages,
    isUserOutOfReality,
  } = useStages();

  const [showCompleteProfileDialog, setShowCompleteProfileDialog] =
    useState(false);

  const [showAdvancedStageDialog, setShowAdvancedStageDialog] = useState(false);

  const isBasicProfileComplete = useMemo(() => {
    if (!userInfo) {
      return true;
    }

    return !!(
      userInfo.email &&
      userInfo.name &&
      userInfo.phoneNumber &&
      userInfo.state &&
      userInfo.city &&
      userInfo.gender &&
      userInfo.birthDate
    );
  }, [{ ...userInfo }]);

  const isProfileStageComplete = useMemo(() => {
    if (!userInfo) {
      return true;
    }

    let isComplete = isBasicProfileComplete;

    if (currentStageNumber > 1) {
      isComplete =
        isComplete &&
        !!(
          userInfo.address &&
          userInfo.numberAddress &&
          userInfo.complement &&
          userInfo.cep &&
          userInfo.shirtSize &&
          userInfo.instagramAccount
        );
    }

    if (currentStageNumber > 2) {
      isComplete =
        isComplete &&
        !!(
          userInfo.cpf &&
          userInfo.phoneNumberResponsible &&
          userInfo.shoeSize &&
          userInfo.pantsSize
        );
    }

    return isComplete;
  }, [{ ...userInfo, currentStageNumber, isBasicProfileComplete }]);

  const isShowProfielDialogRoute = useMemo(() => {
    if (pathname === "/profile" || noAuthRequired.includes(pathname)) {
      return false;
    }

    return true;
  }, [pathname]);

  const goToProfileAndClose = () => {
    navigate("/profile");
    setShowCompleteProfileDialog(false);
    setShowAdvancedStageDialog(false);
  };

  useEffect(() => {
    if (
      isProfileStageComplete ||
      !isShowProfielDialogRoute ||
      !userInfo ||
      isLoadingStages ||
      isUserOutOfReality
    ) {
      return;
    }

    if (!isBasicProfileComplete) {
      setShowCompleteProfileDialog(true);
    } else {
      setShowAdvancedStageDialog(true);
    }
  }, [
    isLoadingStages,
    isBasicProfileComplete,
    isProfileStageComplete,
    pathname,
    userInfo,
    userInfo,
    isShowProfielDialogRoute,
    currentStageNumber,
  ]);

  useEffect(() => {
    if (!isShowProfielDialogRoute) {
      setShowCompleteProfileDialog(false);
    }
  }, [pathname]);

  return (
    <ProfileInfoContext.Provider value={{}}>
      {children}

      <Dialog
        isOpen={showCompleteProfileDialog}
        text="Mestre, verificamos que faltam alguns dados no seu perfil. Clique no botão abaixo para concluir esta etapa, e continuar sua jornada no portal."
        confirmButtonText="Editar dados"
        onConfirm={goToProfileAndClose}
      />

      <Dialog
        isOpen={showAdvancedStageDialog}
        icon={<img src={PartyIcon} />}
        text={`Parabéns! Você avançou para a etapa ${currentStage?.subTitle}. Conclua novos desafios para pontuar na próxima etapa! Para continuar, complete o seu perfil com algumas informações importantes.`}
        confirmButtonText="Completar meu perfil"
        onConfirm={goToProfileAndClose}
      />
    </ProfileInfoContext.Provider>
  );
};
