import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled, { css } from "styled-components";
import Divider from "../Divider";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div<{ hasBottomBorderRadius?: boolean }>`
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.brand.primary};

  display: flex;
  justify-content: space-between;

  ${({ hasBottomBorderRadius }) =>
    hasBottomBorderRadius &&
    css`
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
    `}

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0.5rem;
    flex-direction: column;
  }
`;

export const HeaderFooter = styled.div`
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 0.4rem;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterContentWrapper = styled.div<{ hasCode: boolean }>`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;

  ${({ hasCode, theme }) =>
    hasCode &&
    `
    border: 1px solid ${theme.colors.feedback.positive};
    border-radius: 0.2rem;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10rem 0rem 0rem 10rem;
`;

export const UserImage = styled.img`
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  z-index: 100;

  border-radius: 10rem;
`;

export const HeaderFloatingButtonWrapper = styled.div`
  position: absolute;

  top: 0.5rem;
  right: -3rem;
`;

export const PointsExtractWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const RotatedDivider = styled(Divider)`
  transform: rotate(25deg);
`;
