import { memo } from "react";
import ReactInputMask from "react-input-mask";
import TextField from "../TextField";
import { CpfInputProps } from "./props.type";
import { Box } from "@mui/material";

const CpfInput = (props: CpfInputProps) => {
  return (
    <Box {...props.ContainerProps}>
      <ReactInputMask
        maskChar=" "
        mask="999.999.999-99"
        // @ts-ignore
        children={() => (
          <TextField label={props.label} disabled={props.disabled} error={props.error} helperText={props.error ? props.errorText : undefined}/>
        )}
        {...props}
        onChange={(e) => {
          props.setValue?.(e.target.value);
          props.onChange?.(e);
        }}
      />
    </Box>
  );
};

export default memo(CpfInput);
