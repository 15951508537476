import { Col } from "@/shared/layout";
import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background.card};
  box-shadow: 0rem 0.5rem ${({ theme }) => theme.colors.brand.primary};

  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light};

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
  }
`;

export const CardImage = styled.img`
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);

  width: 12.5rem;
  height: 8rem;
  object-fit: cover;

  @media (max-width: ${mobileBreakpoint}) {
    clip-path: none;
    height: 12rem;
    width: 100%;
  }
`;

export const ScoreWrapper = styled.div`
  display: flex;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.brand.primary};

  padding: 0.25rem 1.5rem;

  align-self: end;
`;

export const ContentWrapper = styled(Col)`
  flex: 1;
  width: 100%;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1rem;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 1rem;
    flex-direction: column;
  }
`;
