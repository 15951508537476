import { sendPasswordRecoveryCode } from "@/services/auth";
import { emailRegex } from "@/utils/regex";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const toggleDialog = () => setShowDialog(!showDialog);

  const emailError = useMemo(() => {
    const match = email.match(emailRegex);

    if (!match && email.length > 0) {
      return "Formato de e-mail inválido";
    }

    return "";
  }, [email]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (!email) {
        return toast("Preencha todos os campos corretamente", {
          type: "error",
        });
      }

      await sendPasswordRecoveryCode(email);
      toggleDialog();
    } catch (err) {
      console.error("Error sending password recovery email", err);
      toast("Erro ao enviar email de recuperação", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDialog = () => {
    navigate(`/password-recovery?email=${email}`);
  };

  const onCancel = () => {
    window.history.back();
  }

  return {
    email,
    setEmail,
    emailError,

    showDialog,
    onConfirmDialog,

    onCancel,

    isLoading,
    onSubmit,
  };
};
