import api from "../api";
import { ChallengeType } from "./types/challenges.types";

export const getMediaChallengeInfo = async (
  id: number
): Promise<ChallengeType | undefined> => {
  const res = await api.get<ChallengeType>(`media-challenges/${id}`);

  return res.data;
};

export const getUploadMediaChallengeImageUrl = async (
  taskId: number
): Promise<string> => {
  const res = await api.get(
    `media-challenges/tasks-submissions-link/${taskId}`
  );

  return res.data;
};

export const getUploadMediaChallengeVideoUrl = async (
  taskId: number
): Promise<string> => {
  const res = await api.get(
    `media-challenges/tasks-submissions-link/${taskId}?type=video`
  );

  return res.data;
};

export const submitMediaChallenge = async (
  id: number,
  mediaUrl: string,
  customInputs?: { [key: string]: string }
): Promise<void> => {
  return api.post(`media-challenges/tasks-submissions/${id}`, {
    mediaUrl,
    customInputs,
  });
};

export const deleteTaskSubmission = async (id: number): Promise<void> => {
  return api.delete(`media-challenges/tasks-submissions/${id}`);
};
