import { UserChallengeStatus } from "@/services/challenges/types/challenges.enums";
import { ChallengeType } from "@/services/challenges/types/challenges.types";
import Theme from "@/utils/theme";
import { useMemo, useState } from "react";

export const useQuizChallengeSubmission = () => {
  const [challenge, setChallenge] = useState<ChallengeType>();

  const [isOpenQuiz, setIsOpenQuiz] = useState(false);
  const toggleQuiz = () => setIsOpenQuiz(!isOpenQuiz);

  const challengeStatus = useMemo(() => {
    if (challenge?.status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Finalizado",
      };
    }

    if (challenge?.status === UserChallengeStatus.REJECTED) {
      return {
        color: Theme.colors.feedback.negative,
        text: "Reprovado",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Envio Pendente",
    };
  }, [challenge?.status]);

  const quizStatus = useMemo(() => {
    if (challenge?.status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Finalizado",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Não Realizado",
    };
  }, [challenge?.status]);

  return {
    challenge,
    setChallenge,
    challengeStatus,
    quizStatus,

    toggleQuiz,
    isOpenQuiz,
  };
};
