import styled from "styled-components";
import BackArrow from "/assets/signup/back-arrow.png";
import Theme from "@/utils/theme";

export const ScrollButton = styled.div`
  position: absolute;
  top: -30%;
  right: 0;
  z-index: 100;
`;

export const BackButton = styled.img.attrs({
  src: BackArrow,
})`
  position: absolute;
  top: 20%;
  left: 1.5rem;
  z-index: 100;
  cursor: pointer;
`;

export const RoundedButton = styled.div`
  cursor: pointer;
  width: 68px;
  height: 68px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  border-radius: 50%;
  border: 1px solid ${Theme.colors.icon.default};

  position: absolute;
  left: 1rem;
`;
