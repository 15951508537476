import { memo } from "react";
import Text from "@/components/Text";
import Spacer from "@/components/Spacer";
import Theme from "@/utils/theme";
import PdcLogo from "/assets/pdc_logo.png";
import { Container, Item } from "./styles";
import { Box } from "@mui/material";
import { PdcFooterProps } from "./props.type";

const PdcFooter = ({
  text = `Você está se\nconectando através do:`,
  ...props
}: PdcFooterProps) => {
  return (
    <Box {...props}>
      <Container>
        <Item justifyEnd>
          <Text
            color={Theme.colors.brand.pdc}
            fontSize="0.75rem"
            fontWeight={300}
          >
            {text}
          </Text>
        </Item>
        <Spacer width="1rem" />
        <Item>
          <img src={PdcLogo} alt="Logo Pdc" />
        </Item>
      </Container>
    </Box>
  );
};

export default memo(PdcFooter);
