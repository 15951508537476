export enum UserChallengeStatus {
  APPROVED = "Approved",
  FINISHED = "Finished",
  SENT = "Sent",
  PENDING = "Pending",
  CLOSED = "Closed",
  REJECTED = "Rejected",
}

export enum TaskChallengeStatus {
  APPROVED = "Approved",
  FINISHED = "Finished",
  SENT = "Sent",
  PENDING = "Pending",
  REJECTED = "Rejected",
}

export enum MediaType {
  PHOTO = "Photo",
  VIDEO = "Video",
}
