import { realityInfo } from "@/utils/realityInfo";
import { FooterVariant } from "./props.type";
import { useMemo } from "react";
import Theme from "@/utils/theme";

export const useFooterWrapper = (variant: FooterVariant) => {
  const onClickGoToWebsite = () =>
    (window.location.href = realityInfo.websiteUrl!);

  const containerColor = useMemo(() => {
    if (variant === "auth") {
      return Theme.colors.background.auth;
    }
  }, [variant]);

  const textColor = useMemo(() => {
    if (variant === "auth") {
      return Theme.colors.neutral.lightest;
    }

    return Theme.colors.text.secondary;
  }, [variant]);

  return { onClickGoToWebsite, containerColor, textColor };
};
