import { useMemo } from "react";
import { CardButtonProps } from "./props.type";

export const useCardButton = ({
  lockButton,
  courseEnded,
  isPending,
}: CardButtonProps) => {
  const buttonText = useMemo(() => {
    if (courseEnded) {
      return "Curso encerrado";
    }

    if (isPending) {
      return "Iniciar curso";
    }

    return "Ver curso";
  }, [courseEnded, isPending]);

  const buttonVariant: "outlined" | "contained" | "locked" = useMemo(() => {
    if (lockButton) {
      return "locked";
    }

    if (!isPending && !lockButton) {
      return "outlined";
    }

    return "contained";
  }, [isPending, lockButton]);

  return { buttonText, buttonVariant };
};
