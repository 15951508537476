import { memo } from "react";
import { Container, Content, ShapeImage } from "./styles";
import ShapeLeft from "/assets/shape_left.svg";
import ShapeRight from "/assets/shape_right.svg";
import { ShapeProps } from "./props.type";

const Shape = ({ width, leftNode, children }: ShapeProps) => {
  return (
    <Container width={width}>
      {leftNode ? leftNode : <ShapeImage src={ShapeLeft} isLeft />}

      <Content>{children}</Content>

      <ShapeImage src={ShapeRight} />
      {/* <ShapeRight  /> */}
    </Container>
  );
};

export default memo(Shape);
