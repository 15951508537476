import { CityResponse, getCities } from "@/utils/get-localities";
import { useEffect, useState } from "react";
import { CitySelectProps } from "./props.type";

export const useCitySelect = ({ state, value, setValue }: CitySelectProps) => {
  const [cities, setCities] = useState<CityResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const updateCities = async () => {
    try {
      setIsLoading(true);
      if (!state) {
        return;
      }

      const res = await getCities(state);
      if (!res.find((c) => c.nome === value)) {
        setValue?.("");
      }
      setCities(res);
    } catch (err) {
      console.error(`Error getting cities for ${state}`, err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateCities();
  }, [state]);

  return { cities, isLoading };
};
