import axios from "axios";

const url = "https://servicodados.ibge.gov.br/api/v1/localidades";

export type StateResponse = {
  id: number;
  sigla: string;
  nome: string;
};
export const getStates = async () => {
  const res = await axios.get<StateResponse[]>(`${url}/estados?orderBy=nome`);

  return res.data;
};

export type CityResponse = {
  id: number;
  nome: string;
};
export const getCities = async (uf: string) => {
  const res = await axios.get<CityResponse[]>(
    `${url}/estados/${uf}/municipios?orderBy=nome`
  );

  return res.data;
};

type RegionType = {
  "regiao-intermediaria": {
    UF: {
      regiao: {
        nome: "Centro-Oeste";
      };
    };
  };
};

export const getRegion = async (uf: string): Promise<string> => {
  const res = await axios.get<RegionType[]>(
    `${url}/estados/${uf}/regioes-imediatas`
  );

  return res.data[0]?.["regiao-intermediaria"].UF.regiao.nome;
};
