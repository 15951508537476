import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light};
  padding-bottom: 0.5rem;
  box-shadow: inset ${({ theme }) => theme.colors.brand.pdc} 0px -5px;
`;

export const Item = styled.div<{ justifyEnd?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  ${({ justifyEnd }) => justifyEnd && `justify-content: end`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light};
`;
