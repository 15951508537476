import resaleApi from "../api/resale";
import { ResaleStore } from "./types/resale.types";

export const verifyResaleCode = async (
  referral: string
): Promise<ResaleStore> => {
  const res = await resaleApi.post(`/api/referral`, { referral });

  return res.data;
};

export const addResaleCodeWithEmail = async (
  referral: string,
  email: string
) => {
  return resaleApi.post(`/api/referral/add`, { referral, email });
};
