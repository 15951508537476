import { useParams } from "react-router-dom";
import MediaChallengeSubmission from "./components/MediaChallengeSubmission";
import QuizChallengeSubmission from "./components/QuizChallengeSubmission";

function ChallengeSubmission() {
  const { type } = useParams();

  if (type === "quiz") {
    return <QuizChallengeSubmission />;
  }

  return <MediaChallengeSubmission />;
}

export default ChallengeSubmission;
