import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled from "styled-components";

interface DialogContentProps {
  maxWidth: string;
}

export const DialogContent = styled.div<DialogContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: ${({ maxWidth }) => maxWidth};
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: 1rem;
  padding: 1rem;

  @media (max-width: ${mobileBreakpoint}) {
    max-width: 95vw;
  }
`;

export const DialogTextWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding: 0px 1rem;
`;
