import { addResaleCodeWithEmail, verifyResaleCode } from "@/services/resale";
import { toast } from "react-toastify";

export const useResaleCode = () => {
  const validateResaleCode = async (code: string) => {
    try {
      await verifyResaleCode(code);
      return true;
    } catch {
      return false;
    }
  };

  const getStoreInfo = async (code: string) => {
    return verifyResaleCode(code);
  };

  const addUserResaleCode = async (code: string, email: string) => {
    try {
      await addResaleCodeWithEmail(code, email);
    } catch (err) {
      toast("Erro ao adicionar código de revenda", { type: "error" });
      console.error("Error adding user resale code", err);
    }
  };

  return { validateResaleCode, addUserResaleCode, getStoreInfo };
};
