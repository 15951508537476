import { StateResponse, getStates } from "@/utils/get-localities";
import { useEffect, useState } from "react";

export const useStateSelect = () => {
  const [states, setStates] = useState<StateResponse[]>([]);

  useEffect(() => {
    (async () => {
      const res = await getStates();
      setStates(res);
    })();
  }, []);

  return { states };
};
