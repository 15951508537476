import { Box } from "@mui/material";
import { ColoredTooltip } from "./styles";
import Text from "@/components/Text";
import { TooltipProps } from "./props.type";
import Close from "@mui/icons-material/Close";
import Theme from "@/utils/theme";

export const Tooltip = ({
  title,
  width = "18rem",
  isOpen,
  onClose,
  placement,
  ...props
}: TooltipProps) => {
  return (
    <ColoredTooltip
      open={isOpen}
      arrow
      onClose={onClose}
      title={
        <Box display="flex" alignItems="center">
          <Text color={Theme.colors.neutral.darkest}>{title}</Text>
          <Box display="flex" style={{ cursor: "pointer" }} onClick={onClose}>
            <Close sx={{ color: Theme.colors.neutral.darkest }} />
          </Box>
        </Box>
      }
      componentsProps={{ tooltip: { sx: { boxShadow: 1 } } }}
      PopperProps={{
        disablePortal: true,
        style: { zIndex: 10, width: width },
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement={placement}
      {...props}
    >
      {props.children ?? <></>}
    </ColoredTooltip>
  );
};
