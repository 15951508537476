import { Box } from "@mui/material";
import TrophyIcon from "/assets/icons/trophy.svg";
import { OutlinedBox } from "./styles";
import PointsSlider from "@/components/PointsSlider";

type Props = {
  points: number;
  maxPoints: number;
};

const PointsProgress = ({ points, maxPoints }: Props) => {
  return (
    <OutlinedBox position="relative" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        width={{xs: "150px", lg:"190px"}}
        position="relative"
      >
        <img src={TrophyIcon} />

        <Box width="100%" ml="1rem">
          <PointsSlider currentPoints={points} totalPoints={maxPoints} />
        </Box>
      </Box>
    </OutlinedBox>
  );
};

export default PointsProgress;
