import { memo } from "react";
import { Box, BoxProps } from "@mui/material";
import Theme from "@/utils/theme";

const CardWithShadow = (props: BoxProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={Theme.colors.background.card}
      border={`1px solid ${Theme.colors.neutral.light}`}
      borderRadius="0.5rem"
      boxShadow="0px 0.2rem 1rem 0.05rem rgba(0,0,0,0.05)"
      {...props}
    ></Box>
  );
};

export default memo(CardWithShadow);
