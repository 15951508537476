import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { Card, Stack } from "@mui/material";
import InfoIcon from "@/ui/icons/InfoIcon";
import { memo } from "react";
import { useStages } from "@/context/StagesContext";
import UserDidntAdvanceCard from "@/components/UserDidntAdvanceCard";

function ChallengesInfoCard() {
  const { isUserOutOfReality } = useStages();

  if (isUserOutOfReality) return <UserDidntAdvanceCard />;

  return (
    <div>
      <Card
        variant="outlined"
        style={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
      >
        <Stack py={1} px={2} flexDirection="row" alignItems="center">
          <InfoIcon size={20} color={Theme.colors.feedback.warning} />

          <Text ml="1rem" color={Theme.colors.text.tertiary}>
            Atenção, Mestre! Complete todos os desafios para aumentar suas
            chances de avançar para a próxima etapa.
          </Text>
        </Stack>
      </Card>
    </div>
  );
}

export default memo(ChallengesInfoCard);
