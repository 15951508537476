import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";

export const OutlinedBox = (props: BoxProps) => (
  <Box
    display="flex"
    border={`1px solid ${Theme.colors.neutral.mid}`}
    p="0.5rem"
    borderRadius="0.5rem"
    alignItems="center"
    {...props}
  />
);
