import { EditImageButton } from "./styles";
import { ProfileImageInputProps } from "./props.type";
import { CircularProgress } from "@mui/material";
import Theme from "@/utils/theme";
import { FileInput } from "@/components/FileInput";
import { getUploadProfileImageUrl } from "@/services/users";
import { useState } from "react";
import EditIcon from "@/ui/icons/EditIcon";

export const ProfileImageInput = ({
  setImageUrl,
  disabled,
}: ProfileImageInputProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FileInput
      disabled={disabled}
      component={
        <EditImageButton>
          {isLoading ? (
            <CircularProgress
              style={{ color: Theme.colors.brand.secondary }}
              size="1rem"
            />
          ) : (
            <EditIcon color={Theme.colors.brand.secondary} />
          )}
        </EditImageButton>
      }
      getSignedUrlService={getUploadProfileImageUrl}
      onUploaded={setImageUrl}
      setIsLoading={setIsLoading}
    />
  );
};
