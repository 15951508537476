import { memo } from "react";
import { Checkbox as MUICheckbox, CheckboxProps, Box } from "@mui/material";
import Theme from "@/utils/theme";

const Checkbox = ({
  children,
  ...props
}: { children?: React.ReactNode } & CheckboxProps) => {
  return (
    <Box display="flex" alignItems="center">
      <MUICheckbox
        size="small"
        {...props}
        sx={{
          ...props.sx,
          color: Theme.colors.brand.secondary,
          "&.Mui-checked": {
            color: Theme.colors.brand.secondary,
          },
        }}
      />
      <Box mt="0.2rem">{children}</Box>
    </Box>
  );
};

export default memo(Checkbox);
