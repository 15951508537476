import styled from "styled-components";

type Props = {
  justify?: string;
  align?: string;
  flex?: number;
  fitWidth?: boolean;
};

const FlexLayout = styled.div<Props>`
  display: flex;

  ${({ justify }) =>
    justify &&
    `
    justify-content: ${justify};
  `};

  ${({ align }) =>
    align &&
    `
    align-items: ${align};
  `}

  ${({ flex }) =>
    flex &&
    `
    flex: ${flex};
  `}

  ${({ fitWidth }) =>
    fitWidth &&
    `
    width: fit-content;
  `}
`;

export const Row = styled(FlexLayout)``;

export const Col = styled(FlexLayout)`
  flex-direction: column;
`;
