import { Box } from "@mui/material";
import { FileInputProps } from "./props.type";
import { useFileInput } from "./useFileInput";

export const FileInput = ({ component, ...props }: FileInputProps) => {
  const { fileInputRef, handleChange, onClickComponent } = useFileInput(props);

  return (
    <>
      <Box
        onClick={onClickComponent}
        style={{ cursor: props.disabled ? "" : "pointer" }}
      >
        {component}
      </Box>

      <input
        type="file"
        value={undefined}
        onChange={handleChange}
        ref={fileInputRef}
        accept={props.variant === "video" ? "video/*" : "image/png, image/jpeg, image/heic"}
        style={{ display: "none" }}
      />
    </>
  );
};
