import { memo } from "react";
import { CustomButtonProps } from "./props.type";
import LockIcon from "/assets/icons/lock.svg";
import { ButtonWrapper } from "./styles";

const Button = ({
  variant = "contained",
  lockIcon,
  ...props
}: CustomButtonProps) => {
  if (variant === "locked") {
    return (
      <ButtonWrapper variant="contained" {...props} disabled>
        {lockIcon ?? <img src={LockIcon} alt="" />}
      </ButtonWrapper>
    );
  }

  return <ButtonWrapper variant={variant} {...props} />;
};

export default memo(Button);
