import { memo } from "react";
import IconButton from "@/components/IconButton";
import { Box, CircularProgress, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNotificationButton } from "../../hooks/useNotificationButton";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import Spacer from "@/components/Spacer";
import { CloseButton, NotificationMenu } from "./styles";
import NotificationItem from "../NotificationItem";
import Divider from "@/components/Divider";

const NotificationButton = () => {
  const {
    anchorEl,
    isOpen,
    onClick,
    onClose,

    isLoading,
    notifications,
    showNotificationModalId,
    toggleNotificationModal,
    onClickNotification,
  } = useNotificationButton();

  return (
    <>
      <IconButton
        icon="notification"
        color={Theme.colors.toolbar.icon}
        onClick={onClick}
      />

      <NotificationMenu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
        <Box py="1rem">
          <Box display="flex" alignItems="center" px="1rem">
            <Text
              fontWeight={500}
              fontSize="1.25rem"
              color={Theme.colors.brand.secondary}
            >
              Notificações{" "}
            </Text>
            <Text color={Theme.colors.text.tertiary} fontSize="1rem">
              ({notifications?.length ?? 0})
            </Text>

            <Spacer grow />

            <CloseButton onClick={onClose}>
              <Close style={{ color: "#D1DAF8" }} />
            </CloseButton>
          </Box>

          <Box display="flex" justifyContent="center" mt="1rem">
            {isLoading && (
              <CircularProgress size="1rem" style={{ justifySelf: "center" }} />
            )}

            {!isLoading && !notifications?.length && (
              <Text color={Theme.colors.text.tertiary}>
                Não há notificações
              </Text>
            )}
          </Box>

          <Divider margin="0px 0px 1rem 0px" />
          {notifications?.map((notification) => (
            <>
              <MenuItem onClick={() => onClickNotification(notification)}>
                <NotificationItem
                  notification={notification}
                  showDetails={notification.id === showNotificationModalId}
                  onCloseDetails={() =>
                    toggleNotificationModal(notification.id)
                  }
                />
              </MenuItem>
              <Divider margin="1rem 0px" />
            </>
          ))}
        </Box>
      </NotificationMenu>
    </>
  );
};

export default memo(NotificationButton);
