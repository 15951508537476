import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";

export const Container = (props: BoxProps) => (
  <Box
    display="flex"
    flex={1}
    border={`1px dashed ${Theme.colors.neutral.mid}`}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    borderRadius="0.5rem"
    py="0.5rem"
    {...props}
  />
);
