import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const TotalChallenges = ({ size = defaultIconProps.size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="20" height="20" fill="url(#pattern0_998_27422)" />
      <defs>
        <pattern
          id="pattern0_998_27422"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_998_27422" transform="scale(0.015625)" />
        </pattern>
        <image
          id="image0_998_27422"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3ElEQVR4nO2az2sTQRTH97+w8UeVBPwPBMGN0kqCMTdLxOTQglkvpc3NkwZyaEVoQOKtB0la9NBEL7YnC1pZQz3ooVETyA+ju2lqig1batpkG3gyoQk1SbMbotSZvAdfWGaHwPezb9/MvgnHYWAwFY5bE3aHczLncHrkEdeEjeu3cDg9ssPlAdvwCFwfvlHmaIn94Am7GhzIqSED9CJinsjK22viaAk1OCD3ap5uAKHezTMLoDJ3BrLx1zWRa6oBbN822hW3MacIJjisTqa2Xo1DIq/UtLU8TjcAxW2Um813AlCZH4RU+lMDQCoTh8rcIMUAhFbz7QBU5s9CKXIJCuKDhvm6yBi5R+YwB2Bj9XHtKSfWiy3GW7RerM3dWA2wAyCV+aJtvEmpzGd2AJQiPCSzSf3ms2n49fwKOwDUkAF2Fy5AKh3T8eTjUApfZKsGqAfaeTGkCYDMYa4IqgdSlm5qAlAWHewC2Hx7XxPA5so9dgHkPoQaRpOSDD/EqZrIdX2czGEWQGHFC8lvX6EgTsPe0/ONcXJNxsg9kiXMAlBZ/RpUEICpbQZUZk/2dwbs3D33VyBQC0A5Qt3+PgJwUdoQUdrKKDEHYPuO0aYPglFS3KZrzAH414EAXIxkgHd5yOePmquB9zx00kzUDJ6XV2EsbG2vBYs8GrHQd0Y4E+U1zdflf2c+GkDYCqNhS9fF9NhDr/m6OgEg4miLAALgMQMC+ArwWAMCtBZBMVHwxaRiVauxuSYVYemjBM/E9B+ivgiu6TBfV0wqsgcg0eX5HgIQMQMAXwERawBgERT/k1Vgb/qUrzJrqGq1oUkrm7S0m1ta1K8CZR3mD0NgDoDa5YEEAhAwAwBfAQFrAGARFHAVAFwGBdwHAG6E8rgTBNwK5/v4Yyj2/Sf00hT1Ry/DsR6O7j487S338DmcfDKlCwIxv9iuLf7mkS4IxPxkh+NxYn4sbO36nyYYXJ/Eb4Lsh+LC8d2BAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default memo(TotalChallenges);
