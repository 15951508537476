import { memo } from "react";
import TextField from "@/components/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { PasswordInputProps } from "./props.type";
import { usePasswordInput } from "./usePasswordInput";
import EyeOffIcon from "@/ui/icons/EyeOffIcon";
import EyeIcon from "@/ui/icons/EyeIcon";

const PasswordInput = ({
  showPassword: showPasswordProp,
  hideIcon,
  onToggle,
  ...props
}: PasswordInputProps) => {
  const { showPassword, toggleAux } = usePasswordInput(showPasswordProp);

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      size="medium"
      blockChars={[" "]}
      InputProps={{
        endAdornment: !hideIcon ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                onToggle?.();
                toggleAux();
              }}
              edge="end"
              size={props.size}
            >
              {showPassword ? (
                <EyeOffIcon size={props.size === "small" ? 20 : 24} />
              ) : (
                <EyeIcon size={props.size === "small" ? 20 : 24} />
              )}
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
      {...props}
    />
  );
};

export default memo(PasswordInput);
