import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const MenuIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 4.00004C1.3335 3.82323 1.40373 3.65366 1.52876 3.52864C1.65378 3.40361 1.82335 3.33337 2.00016 3.33337H14.0002C14.177 3.33337 14.3465 3.40361 14.4716 3.52864C14.5966 3.65366 14.6668 3.82323 14.6668 4.00004C14.6668 4.17685 14.5966 4.34642 14.4716 4.47145C14.3465 4.59647 14.177 4.66671 14.0002 4.66671H2.00016C1.82335 4.66671 1.65378 4.59647 1.52876 4.47145C1.40373 4.34642 1.3335 4.17685 1.3335 4.00004ZM1.3335 8.02137C1.3335 7.84456 1.40373 7.67499 1.52876 7.54997C1.65378 7.42495 1.82335 7.35471 2.00016 7.35471H14.0002C14.177 7.35471 14.3465 7.42495 14.4716 7.54997C14.5966 7.67499 14.6668 7.84456 14.6668 8.02137C14.6668 8.19818 14.5966 8.36775 14.4716 8.49278C14.3465 8.6178 14.177 8.68804 14.0002 8.68804H2.00016C1.82335 8.68804 1.65378 8.6178 1.52876 8.49278C1.40373 8.36775 1.3335 8.19818 1.3335 8.02137ZM2.00016 11.3767C1.82335 11.3767 1.65378 11.4469 1.52876 11.572C1.40373 11.697 1.3335 11.8666 1.3335 12.0434C1.3335 12.2202 1.40373 12.3898 1.52876 12.5148C1.65378 12.6398 1.82335 12.71 2.00016 12.71H14.0002C14.177 12.71 14.3465 12.6398 14.4716 12.5148C14.5966 12.3898 14.6668 12.2202 14.6668 12.0434C14.6668 11.8666 14.5966 11.697 14.4716 11.572C14.3465 11.4469 14.177 11.3767 14.0002 11.3767H2.00016Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(MenuIcon);
