import { memo } from "react";
import Select from "../Select";
import { MenuItem } from "@mui/material";
import { useStateSelect } from "./useStateSelect";
import { CustomTextFieldProps } from "../TextField/props.type";

const StateSelect = (props: CustomTextFieldProps) => {
  const { states } = useStateSelect();

  return (
    <Select label="Estado" {...props}>
      {states.map((state) => (
        <MenuItem key={`state-select-${state.sigla}`} value={state.sigla}>
          {state.nome}
        </MenuItem>
      ))}
    </Select>
  );
};

export default memo(StateSelect);
