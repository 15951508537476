import { memo } from "react";
import { NotificationItemProps } from "./props.type";
import { Box } from "@mui/material";
import Text from "@/components/Text";
import UnseenNotificationIcon from "/assets/icons/unseen-notification.svg";
import SeenNotificationIcon from "/assets/icons/seen-notification.svg";
import Theme from "@/utils/theme";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { getFormattedDate } from "@/helpers/date.helper";

const NotificationButton = ({
  notification,
  showDetails,
  onCloseDetails,
}: NotificationItemProps) => {
  return (
    <>
      <Box
        maxWidth="29rem"
        display="flex"
        flexDirection="column"
        alignItems="end"
      >
        <Text color={Theme.colors.text.tertiary}>
          {getFormattedDate(notification.createdAt)}
        </Text>

        <Box display="flex" mt="4px">
          <img
            src={
              notification.seen ? SeenNotificationIcon : UnseenNotificationIcon
            }
          />

          <Box ml="1rem">
            <Text>{notification.title}</Text>
            <Text color={Theme.colors.text.secondary}>{notification.text}</Text>
          </Box>
        </Box>
      </Box>

      <Modal isOpen={showDetails} onClose={onCloseDetails}>
        <Box p="2rem" display="flex" flexDirection="column" alignItems="center">
          <Text fontSize="1rem">NOTIFICAÇÂO</Text>

          <Box
            mt="1.5rem"
            border={`1px solid ${Theme.colors.neutral.light}`}
            borderRadius="1rem"
            p="1rem"
          >
            <Text>{notification.title}</Text>
            <Text color="#515961" mt="0.5rem">
              {notification.text}
            </Text>
          </Box>

          <Box display="flex" alignSelf="end" mt="1rem" flex={1}>
            <Button
              variant="outlined"
              onClick={onCloseDetails}
              fullWidth={false}
              style={{ padding: "0.5rem 2rem" }}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default memo(NotificationButton);
