import { useEffect, useMemo, useState } from "react";
import { MediaDetailsDialogProps } from "./props.type";
import { phoneNumberRegex } from "@/utils/regex";

export const useMediaDetailsDialog = ({
  onClose,
  customInputs,
  taskInputs,
  setTaskInput,
}: MediaDetailsDialogProps) => {
  const hasCustomInputs = !!customInputs.length;
  const [taskInputsAux, setTaskInputsAux] = useState(taskInputs);

  const setKeyValue = (key: string, value: string) => {
    setTaskInputsAux({ ...taskInputsAux, [key]: value });
  };

  const onClickCancel = () => {
    onClose();
    setTaskInputsAux(taskInputs);
  };

  const onClickSave = () => {
    setTaskInput(taskInputsAux);
    onClose();
  };

  useEffect(() => {
    setTaskInputsAux(taskInputs);
  }, [taskInputs]);

  const isValidPhoneNumber = (val: string) => {
    const rawNumber = val?.replace(/[^\d]/g, "") ?? "";
    if (rawNumber?.length === 0) return true;

    return phoneNumberRegex.test(rawNumber);
  };

  const errors = useMemo(() => {
    const aux: { [key: string]: string } = {};

    Object.keys(taskInputsAux).forEach((key) => {
      const input = customInputs.find((i) => i.key === key);

      if (
        input?.variant === "phonenumber" &&
        !isValidPhoneNumber(taskInputsAux[key])
      ) {
        aux[key] = "Número inválido";
      }
    });

    return aux;
  }, [taskInputsAux]);

  const isValidInputs = useMemo(() => {
    return Object.keys(errors).length === 0;
  }, [errors]);

  return {
    hasCustomInputs,
    taskInputsAux,

    errors,
    isValidInputs,

    setKeyValue,
    onClickCancel,
    onClickSave,
  };
};
