import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled from "styled-components";

export const DesktopOnly = styled.div`
  display: flex;

  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  display: flex;

  @media (min-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
