import { useGetCompletedChallenges } from "@/hooks/useGetCompletedChallenges";
import { useCallback, useMemo, useState } from "react";

export const useChallenges = () => {
  const { data: completedChallengesData } = useGetCompletedChallenges();
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const [challengesPerStage, setChallengesPerStage] = useState<{
    [stageId: number]: number | undefined;
  }>({});
  const updateChallengePerStage = (stageId: number, amount?: number) => {
    setChallengesPerStage({ ...challengesPerStage, [stageId]: amount });
  };

  const completedChallenges = useMemo(() => {
    if (!completedChallengesData) {
      return 0;
    }

    return completedChallengesData.completedChallenges;
  }, [completedChallengesData]);

  const getIsOpenAccordion = useCallback(
    (index: number) => {
      return expandedIndexes.includes(index);
    },
    [expandedIndexes]
  );

  return {
    completedChallenges,
    totalChallenges: completedChallengesData?.totalChallenges || 0,
    setExpandedIndexes,
    getIsOpenAccordion,

    challengesPerStage,
    updateChallengePerStage,
  };
};
