import { Box, BoxProps } from "@mui/material";

export const ModalContent = (props: BoxProps) => (
  <Box
    alignItems="center"
    width={{ xs: "100%", sm: "25rem" }}
    {...props}
    sx={{
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      borderRadius: "1rem",
      ...props.sx,
    }}
  />
);
