import { memo } from "react";
import { IconProps, defaultIconProps } from "./icon.props.type";

const EditIcon = ({
  color = defaultIconProps.color,
  size = defaultIconProps.size,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.455 5.41583C21.55 5.56004 21.5924 5.73261 21.5749 5.90442C21.5574 6.07622 21.4811 6.23673 21.359 6.35883L12.166 15.5508C12.072 15.6448 11.9546 15.7121 11.826 15.7458L7.99702 16.7458C7.87045 16.7788 7.73744 16.7782 7.61121 16.7439C7.48498 16.7096 7.3699 16.6429 7.27741 16.5504C7.18492 16.4579 7.11822 16.3429 7.08395 16.2166C7.04967 16.0904 7.049 15.9574 7.08202 15.8308L8.08202 12.0028C8.11121 11.8879 8.16628 11.7812 8.24302 11.6908L17.47 2.46983C17.6106 2.32938 17.8013 2.25049 18 2.25049C18.1988 2.25049 18.3894 2.32938 18.53 2.46983L21.359 5.29783C21.3949 5.33383 21.4271 5.37337 21.455 5.41583ZM19.768 5.82783L18 4.06083L9.48202 12.5788L8.85702 14.9718L11.25 14.3468L19.768 5.82783Z"
        fill={color}
      />
      <path
        d="M19.641 17.1599C19.9143 14.8238 20.0016 12.4698 19.902 10.1199C19.8998 10.0645 19.9091 10.0093 19.9292 9.95769C19.9494 9.90608 19.9799 9.85916 20.019 9.81989L21.003 8.83589C21.0299 8.80885 21.064 8.79014 21.1013 8.78203C21.1385 8.77392 21.1773 8.77674 21.213 8.79015C21.2487 8.80356 21.2798 8.827 21.3025 8.85764C21.3252 8.88828 21.3386 8.92483 21.341 8.96289C21.5262 11.7541 21.4559 14.5564 21.131 17.3349C20.895 19.3569 19.271 20.9419 17.258 21.1669C13.7633 21.5539 10.2366 21.5539 6.74199 21.1669C4.72999 20.9419 3.10499 19.3569 2.86899 17.3349C2.45439 13.7903 2.45439 10.2095 2.86899 6.66489C3.10499 4.64289 4.72899 3.05789 6.74199 2.83289C9.39436 2.53877 12.0667 2.46752 14.731 2.61989C14.7691 2.62262 14.8057 2.63623 14.8363 2.6591C14.8669 2.68196 14.8904 2.71313 14.9038 2.74891C14.9173 2.78468 14.9202 2.82357 14.9123 2.86096C14.9044 2.89835 14.8859 2.93268 14.859 2.95989L13.866 3.95189C13.8271 3.99064 13.7807 4.02101 13.7296 4.04113C13.6785 4.06125 13.6239 4.0707 13.569 4.06889C11.3458 3.99331 9.11995 4.07853 6.90899 4.32389C6.26294 4.39539 5.65985 4.68261 5.19715 5.13914C4.73445 5.59567 4.43917 6.19485 4.35899 6.83989C3.95804 10.2682 3.95804 13.7316 4.35899 17.1599C4.43917 17.8049 4.73445 18.4041 5.19715 18.8606C5.65985 19.3172 6.26294 19.6044 6.90899 19.6759C10.264 20.0509 13.736 20.0509 17.092 19.6759C17.7381 19.6044 18.3411 19.3172 18.8038 18.8606C19.2665 18.4041 19.5608 17.8049 19.641 17.1599Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(EditIcon);
