import api from "../api";
import { UserInfo } from "./types/user.types";

export const getUserInfo = async (): Promise<UserInfo | undefined> => {
  const res = await api.get<UserInfo>("users/me");

  return res.data;
};

export const updateUserInfo = async (
  info: Partial<UserInfo>
): Promise<void> => {
  return api.put("users/me", { ...info });
};

export const getUploadProfileImageUrl = async (): Promise<string> => {
  const res = await api.get("users/profile-image-link");

  return res.data;
};
