import { memo } from "react";
import { Box } from "@mui/material";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import Spacer from "@/components/Spacer";
import { AccordionDetailItemProps } from "./props.type";
import Button from "@/components/Button";
import { useAccordionDetailItem } from "./useAccordionDetailItem";
import { CardImage, DateWrapper } from "./styles";
import Chip from "@/components/Chip";
import ChevronRight from "@/ui/icons/ChevronRight";

const AccordionDetailItem = ({
  stageId,
  challenge,
}: AccordionDetailItemProps) => {
  const { statusText, buttonType, handleStartChallenge } =
    useAccordionDetailItem(stageId, challenge);

  return (
    <Box display="flex" flex={1} p="0.5rem 1rem">
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        border={`1px solid ${Theme.colors.neutral.mid}`}
        borderRadius="0.5rem"
        overflow={"hidden"}
      >
        <Box display="flex" flex={1}>
          <Box display={{ xs: "none", sm: "flex" }} height="auto">
            <CardImage src={challenge.thumbnailUrl} />
          </Box>

          <Box
            mx="1rem"
            py="1rem"
            display="flex"
            flexDirection="column"
            alignSelf="center"
            flex={1}
          >
            <Text>
              Desafio {challenge.order}: {challenge.shortTitle}
            </Text>

            <Spacer height="0.5rem" />

            <Box
              display="flex"
              flex={1}
              justifyContent="space-between"
              alignItems={
                challenge.type === "InPersonChallenge" ? "center" : "none"
              }
            >
              {buttonType === "pending" && (
                <Button
                  disableElevation
                  fullWidth={false}
                  style={{
                    padding: "0px 1rem",
                    borderRadius: "0.5rem",
                    minWidth: "10rem",
                  }}
                  onClick={handleStartChallenge}
                >
                  Iniciar Desafio
                  <Spacer width={"0.5rem"} />
                  <ChevronRight
                    size={16}
                    color={Theme.colors.button.contained.primary.text}
                  />
                </Button>
              )}

              {buttonType === "started" && (
                <Button
                  disableElevation
                  fullWidth={false}
                  variant="outlined"
                  style={{
                    padding: "0px 1rem",
                    borderRadius: "0.5rem",
                    minWidth: "10rem",
                  }}
                  onClick={handleStartChallenge}
                >
                  Ver Desafio
                </Button>
              )}

              {buttonType === "locked" && (
                <Button
                  disableElevation
                  variant="locked"
                  fullWidth={false}
                  style={{
                    padding: "0.3rem 0.5rem",
                    borderRadius: "0.5rem",
                    minWidth: "10rem",
                  }}
                />
              )}

              {buttonType === "points" && (
                <Text fontSize="1rem" color={Theme.colors.neutral.darkest}>
                  Nota obtida:{" "}
                  <span
                    style={{
                      color: Theme.colors.feedback.positive,
                      fontWeight: 500,
                    }}
                  >
                    {challenge.earnedPoints ?? "-"} pts
                  </span>
                </Text>
              )}

              {statusText.type === "in-person" ? (
                <DateWrapper bgcolor={statusText.color}>
                  <Text
                    color={statusText.textColor}
                    align="center"
                    fontSize="0.75rem"
                  >
                    {statusText.text}
                  </Text>
                </DateWrapper>
              ) : (
                <Chip
                  label={statusText.text}
                  color={statusText.color}
                  textColor={statusText.textColor}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AccordionDetailItem);
