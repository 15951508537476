import { getCourses } from "@/services/courses";
import { getCodeForPdc } from "@/services/courses/pdc";
import { useQuery } from "react-query";

export const useCourses = () => {
  const fetchCourses = () => getCourses();
  const { data: courses } = useQuery("get-courses", fetchCourses);
  const { data: pdcCode, refetch } = useQuery("get-pdc-code", getCodeForPdc);

  const onGoToCourse = async (url: string) => {
    if (!pdcCode) {
      await refetch().catch((err) => {
        console.error("Error getting user code", err);
      });
    }

    window.open(`${url}?code=${pdcCode}`);
  };

  return { courses, onGoToCourse };
};
