import { memo } from "react";
import {
  Chip as MUIChip,
  ChipProps,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Theme from "@/utils/theme";

const Chip = ({
  color,
  textColor,
  ...props
}: { color?: string; textColor?: string } & Omit<ChipProps, "color">) => {
  const theme = createTheme({
    palette: {
      primary: { main: color ?? Theme.colors.brand.secondary },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            color: textColor ?? Theme.colors.neutral.lightest,
          },
          label: {
            whiteSpace: "break-spaces",
            textAlign: "center",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MUIChip {...props} color="primary" />
    </ThemeProvider>
  );
};

export default memo(Chip);
