import { memo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { QuizDetailsDialogProps } from "./props.type";
import Text from "@/components/Text";
import { useQuizQuestionsDialog } from "./useQuizQuestionsDialog";
import PointsSlider from "@/components/PointsSlider";
import Theme from "@/utils/theme";
import Divider from "@/components/Divider";
import {
  AlternativeBullet,
  AlternativeWrapper,
  QuestionNumberWrapper,
  QuestionWrapper,
} from "./styles";
import Button from "@/components/Button";
import Spacer from "@/components/Spacer";
import Modal from "@/components/Modal";
import Dialog from "@/components/Dialog";
import QuizPointsDialog from "./components/QuizPointsDialog";
import InfoIcon from "@/ui/icons/InfoIcon";

const QuizQuestionsDialog = (props: QuizDetailsDialogProps) => {
  const { isOpen, challenge, onClose } = props;

  const {
    currentQuestionIndex,
    currentQuestion,
    isLastQuestion,

    currentAlternatives,
    currentAnswer,
    answers,
    getIsSelected,
    setCurrentQuestionAnswer,

    isOpenLeaveDialog,
    toggleLeaveDialog,

    onLeaveQuiz,
    onPreviousQuestion,
    onNextQuestion,

    isOpenConfirmationDialog,
    toggleConfirmationDialog,

    onSubmit,
    isLoading,
    totalCorrect,
    totalWrong,
  } = useQuizQuestionsDialog(props);

  if (challenge?.submission) {
    return (
      <QuizPointsDialog
        isOpen={isOpen}
        onClose={onClose}
        challenge={challenge}
        totalCorrect={totalCorrect}
        totalWrong={totalWrong}
      />
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        containerProps={{
          width: { xs: "100%", sm: "50vw" },
        }}
      >
        <Box
          py="1.5rem"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text fontSize="1rem" mb="1.5rem">
            QUIZ
          </Text>

          <Box width="90%">
            <PointsSlider
              currentPoints={answers.length}
              totalPoints={challenge.questions?.length ?? 1}
              usePercentage
            />

            <QuestionWrapper>
              <Box display="flex" p="1rem" justifyContent="center">
                <Text color={Theme.colors.brand.secondary}>
                  {currentQuestionIndex + 1}ª PERGUNTA
                </Text>
              </Box>

              <Divider />

              <Box display="flex" p="1rem" alignItems="center">
                <QuestionNumberWrapper>
                  <Text color="white" fontSize="1.3rem">
                    {currentQuestionIndex + 1}
                  </Text>
                </QuestionNumberWrapper>

                <Text>{currentQuestion?.question}</Text>
              </Box>
            </QuestionWrapper>

            <Text color="#515961" fontWeight={300} mt="1rem">
              Apenas uma alternativa está correta:
            </Text>

            {currentAlternatives.map(({ id, answer }) => (
              <AlternativeWrapper
                isSelected={getIsSelected(id)}
                onClick={() => {
                  if (isLoading) {
                    return;
                  }

                  setCurrentQuestionAnswer(id);
                }}
              >
                <AlternativeBullet isSelected={getIsSelected(id)} />
                <Text>{answer}</Text>
              </AlternativeWrapper>
            ))}

            <Box
              display="flex"
              flexDirection={{ xs: "column-reverse", sm: "row" }}
              alignItems="center"
              mt="1rem"
            >
              <Button
                color="error"
                variant="text"
                fullWidth={false}
                onClick={toggleLeaveDialog}
                disabled={isLoading}
                style={{ padding: "0.1rem" }}
              >
                Sair do Quiz
              </Button>
              <Spacer grow />

              <Box display="flex" alignItems="center" mb="0.5rem">
                <Button
                  variant="outlined"
                  fullWidth={false}
                  style={{ padding: "0.3rem", width: "8rem" }}
                  disabled={currentQuestionIndex === 0 || isLoading}
                  onClick={onPreviousQuestion}
                >
                  Anterior
                </Button>

                <Spacer width="1rem" />
                <Button
                  fullWidth={false}
                  style={{ padding: "0.3rem", width: "8rem" }}
                  onClick={onNextQuestion}
                  disabled={!currentAnswer || isLoading}
                >
                  {isLoading ? (
                    <CircularProgress
                      size="1.5rem"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <>{isLastQuestion ? "Finalizar Quiz" : "Avançar"}</>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Dialog
        isOpen={isOpenLeaveDialog}
        icon={<InfoIcon color={Theme.colors.feedback.negative} />}
        text="Atenção! Você está saindo do quiz. Ao confirmar, todo o seu progresso atual será perdido."
        confirmButtonText="Continuar no Quiz"
        onConfirm={toggleLeaveDialog}
        closeButtonText="Sair do Quiz"
        onClose={onLeaveQuiz}
      />

      <Dialog
        isOpen={isOpenConfirmationDialog}
        text="Ao finalizar, todas as respostas serão enviadas e não será possível refazer esse quiz. Deseja continuar com a finalização?"
        confirmButtonText="Finalizar Quiz"
        onConfirm={() => {
          toggleConfirmationDialog();
          onSubmit();
        }}
        closeButtonText="Continuar no Quiz"
        onClose={toggleConfirmationDialog}
      />
    </>
  );
};

export default memo(QuizQuestionsDialog);
