import { uploadImage } from "@/services/shared.services";
import { useMemo, useRef } from "react";
import { toast } from "react-toastify";
import heic2any from "heic2any";
import { FileInputProps } from "./props.type";

const BYTE_SIZE = 1048576;
const DEFAULT_MAX_VIDEO_SECONDS = 30;

export const useFileInput = ({
  disabled,
  maxMbSize,
  getSignedUrlService,
  maxVideoDuration,
  onUploaded,
  setIsLoading,
  variant,
}: Partial<FileInputProps>) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const maxSeconds = useMemo(() => {
    return maxVideoDuration ?? DEFAULT_MAX_VIDEO_SECONDS;
  }, [maxVideoDuration]);

  const maxDurationText = useMemo(() => {
    let text = "A duração máxima permitida é de";
    const minutes = Math.floor(maxSeconds / 60);
    const seconds = maxSeconds - 60 * minutes;

    if (minutes === 1) {
      text += ` ${minutes} minuto`;
    } else if (minutes > 1) {
      text += ` ${minutes} minutos`;
    }

    if (minutes && seconds) {
      text += " e";
    }

    if (seconds === 1) {
      text += ` ${seconds} segundo`;
    } else if (seconds > 1) {
      text += ` ${seconds} segundos`;
    }

    return text;
  }, [maxVideoDuration]);

  const handleChange = async (event: any) => {
    try {
      const fileUploaded: File = event.target.files[0];
      if (!fileUploaded || !getSignedUrlService) {
        return;
      }

      if (maxMbSize && fileUploaded.size > maxMbSize * BYTE_SIZE) {
        return toast(`O tamanho máximo permitido é de ${maxMbSize}MB`, {
          type: "error",
        });
      }

      if (variant === "video") {
        const isValid = await isValidVideoDuration(fileUploaded);

        if (!isValid) {
          return toast(maxDurationText, { type: "error" });
        }
      }

      setIsLoading?.(true);

      const url = await getSignedUrlService();

      if (variant !== "video") {
        try {
          const result = await heic2any({ blob: fileUploaded });

          await uploadImage(url, result as File);
        } catch (error) {
          await uploadImage(url, fileUploaded);
        }
      } else {
        await uploadImage(url, fileUploaded);
      }
      resetFileInput();
      const imageUrl = url.split("?")[0];
      onUploaded?.(imageUrl);
    } catch (err) {
    } finally {
      setIsLoading?.(false);
    }
  };

  const onClickComponent = async () => {
    if (disabled) {
      return;
    }

    fileInputRef.current?.click();
  };

  const resetFileInput = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.value = "";
  };

  const isValidVideoDuration = async (file: File) => {
    return new Promise((resolve: (val: boolean) => void) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);

        if (video.duration > maxSeconds) {
          video.remove();
          resolve(false);
        } else {
          video.remove();
          resolve(true);
        }
      };
    });
  };

  return { fileInputRef, handleChange, onClickComponent };
};
