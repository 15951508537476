import { memo } from "react";
import Theme from "@/utils/theme";
import { Box, Card } from "@mui/material";
import { useStages } from "@/context/StagesContext";
import InfoIcon from "@/ui/icons/InfoIcon";
import { getFormattedDate } from "@/helpers/date.helper";
import HighlightedText from "@/components/HighlightedText";
import UserDidntAdvanceCard from "@/components/UserDidntAdvanceCard";

const CurrentStageCard = () => {
  const { currentStage, isStageClosed, isUserOutOfReality } = useStages();

  if (!currentStage) return null;

  if (isUserOutOfReality) return <UserDidntAdvanceCard />;

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
    >
      <Box display="flex" py="0.5rem" px="1rem">
        <Box display={!isStageClosed ? "flex" : "none"} alignItems="center">
          <InfoIcon size={20} />

          <HighlightedText
            ml="1rem"
            fontWeight={400}
            hightlightWeight={400}
            text={`Você está na etapa: {{${
              currentStage.subTitle
            }}} | Finalização desta etapa: {{${getFormattedDate(
              currentStage.endDate
            )}}}`}
            color={Theme.colors.text.secondary}
            highlightColor={Theme.colors.feedback.info}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default memo(CurrentStageCard);
