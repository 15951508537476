import { useUser } from "@/context/UserContext";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useToolbar = () => {
  const { logOut } = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const toggleDrawer = () => setIsOpenDrawer(!isOpenDrawer);

  const pages = [
    { title: "Página Inicial", path: "dashboard" },
    { title: "Meu Perfil", path: "profile" },
    { title: "Desafios", path: "challenges" },
    { title: "Cursos", path: "courses" },
    { title: "Ajuda", path: "help" },
    { title: "Regulamento", path: "regulation" },
  ];

  const currentPageTitle = useMemo(() => {
    const pathBase = pathname.split("/")[1];

    return pages.find((p) => p.path === pathBase)?.title ?? "";
  }, [pathname, pages]);

  const onClickLogo = () => navigate("/dashboard");
  const onClickUserInfo = () => navigate("/profile");

  const onClickLogOut = () => {
    logOut();
  };

  return {
    currentPageTitle,
    onClickLogo,
    onClickUserInfo,
    onClickLogOut,
    isOpenDrawer,
    toggleDrawer,
  };
};
