import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral.light};
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
`;

export const CurrentPointsContainer = styled.div<{ widthPercentage: number }>`
  display: flex;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  min-width: fit-content;
  flex: ${({ widthPercentage }) => widthPercentage};
  z-index: 10;

  background-color: ${({ theme }) => theme.colors.brand.secondary};
`;
