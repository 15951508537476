import { memo } from "react";
import { Typography, TypographyProps } from "@mui/material";
import Theme from "@/utils/theme";

const Text = (props: TypographyProps) => {
  return (
    <Typography
      display="flex"
      fontFamily="Roboto"
      fontSize="0.87rem"
      fontWeight={400}
      whiteSpace="break-spaces"
      color={Theme.colors.neutral.dark}
      lineHeight={1.2}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

export default memo(Text);
