import axios from "axios";

const pdcApi = axios.create({
  baseURL: import.meta.env.VITE_PDC_API_URL,
  timeout: 15000,
});

pdcApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("AccessToken");
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default pdcApi;
